import styled from "styled-components";
import ReactFlagsSelect from "react-flags-select";

export const MainContainer = styled.div`
  max-width: 1050px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 470px) {
  }
`;

export const Heading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.24px;
  text-align: center;
  color: #000000;
  margin-bottom: 0px;
  margin-top: 0px;
`;

export const SubHeading = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #36a369;
  margin-bottom: 0px;
  margin-top: 0px;
`;

export const ExtraInfo = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #727272;
  text-align: center;
  margin-bottom: 38px;
  margin-top: 0px;
`;

export const InputCont = styled.div`
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 38px;

  @media (max-width: 470px) {
    width: 90%;
  }
`;

export const Title = styled.h4`
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.24px;
  color: #000000;
  margin-bottom: 10px; 
`;

export const Table = styled.table`
  width: 100%;
  margin: 40px auto;
  border: 1px solid #36a369;
  border-collapse: collapse;
`;

export const Tr = styled.tr`
  border: 1px solid #36a369;
`;

export const Th = styled.th`
  border: 1px solid #36a369;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  color:#36a369;
`;

export const Td = styled.td`
  border: 1px solid #36a369;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
`;

export const Green = styled.span`

color:#36a369;
`;

export const NoOfDid = styled.input.attrs({
  type: "number",
  id: "noOfDid",
})`
  max-width: 440px;
  width: 100%;
  height: 38px;
  margin: 0 auto;
  border: 1px solid #36a369;
  margin-bottom: 10px;
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #989898;
  padding: 0px 19px; 

  ::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #989898;
  }
`;

export const Error = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ff3333;
`;
// export const div = styled.div``;
