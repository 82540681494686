import React from "react";
import {
  MainContainer,
  FgLogo,
  ButtonCont,
  BackBtn,
  NextBtn,
  Spacer,
  SocialContainer,
  SingleSocial,
  Icon,
  SocialName,
  IconSocial,
  FgLink,
} from "./Footer.elements";

import axios from "axios";
import {
  REGISTER_USER,
  REGISTER_AGENT_INFO,
  REGISTER_SERVER_TYPE,
  REGISTER_DIALER_SUPPORT,
  REGISTER_PAYMENT,
  REGISTER_SERVICE_TYPE,
  REGISTER_INBOUND_OUTBOUND_VOL,
  REGISTER_SOURCE_OF_NUMBERS,
  REGISTER_COMPANY_INFO,
  REGISTER_USERNAME_IP,
  REGISTER_DIALER_SERVER_TYPE,
  REGISTER_USERNAME_VOIP_BALANCE,
  REGISTER_NO_OF_DID,
  REGISTER_AMZ_INFO,
  REGISTER_SWITCH,
  REGISTER_SWITCH_CAPACITY,
} from "../Endpoints";

const Footer = ({
  handleStep,
  currentStep,
  selectedService,
  regFormInfo,
  CartItemInfo,
  handleBackStep,
}) => {
  const registerUser = async () => {
    const response = await axios.post(REGISTER_USER, regFormInfo);
    console.log(response);
  };

  const registerAgentInfo = async () => {
    const response = await axios.post(REGISTER_AGENT_INFO, regFormInfo);
    console.log(response);
  };

  const registerServerType = async () => {
    const response = await axios.post(REGISTER_SERVER_TYPE, regFormInfo);
    console.log(response);
  };

  const registerDialerSupport = async () => {
    const response = await axios.post(REGISTER_DIALER_SUPPORT, regFormInfo);
    console.log(response);
  };

  const registerPaymentConsent = async () => {
    const response = await axios.post(REGISTER_PAYMENT, regFormInfo);
    console.log(response);
  };

  const registerServiceType = async () => {
    const response = await axios.post(REGISTER_SERVICE_TYPE, regFormInfo);
    console.log(response);
  };

  const registerInboundOutbound = async () => {
    const response = await axios.post(
      REGISTER_INBOUND_OUTBOUND_VOL,
      regFormInfo
    );
    console.log(response);
  };

  const registerSourceOfNumbers = async () => {
    const response = await axios.post(REGISTER_SOURCE_OF_NUMBERS, regFormInfo);
    console.log(response);
  };

  const registerCompanyInfo = async () => {
    const response = await axios.post(REGISTER_COMPANY_INFO, regFormInfo);
    console.log(response);
  };

  const registerUserNameIp = async () => {
    const response = await axios.post(REGISTER_USERNAME_IP, regFormInfo);
    console.log(response);
  };

  const registerDialerServiceType = async () => {
    const response = await axios.post(REGISTER_DIALER_SERVER_TYPE, regFormInfo);
    console.log(response);
  };

  const registerUsernameVoipBalance = async () => {
    const response = await axios.post(
      REGISTER_USERNAME_VOIP_BALANCE,
      regFormInfo
    );
    console.log(response);
  };

  const registerNoOfDid = async () => {
    const response = await axios.post(REGISTER_NO_OF_DID, regFormInfo);
    console.log(response);
  };

  const registerAmzIngfo = async () => {
    const response = await axios.post(REGISTER_AMZ_INFO, regFormInfo);
    console.log(response);
  };

  const registerSwitch = async () => {
    const response = await axios.post(REGISTER_SWITCH, regFormInfo);
    console.log(response);
  };

  const registerSwitchCapacity = async () => {
    const response = await axios.post(REGISTER_SWITCH_CAPACITY, regFormInfo);
    console.log(response);
  };

  // const registerCompanyCertificate = async () => {
  //   const data = new FormData();
  //   data.append("file", regFormInfo.companyCertificate);
  //   data.append("email", regFormInfo.email);

  //   const response = await axios.post(REGISTER_COMPANY_CERTIFICATE, data, {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   });
  //   console.log(response);
  // };

  const handleBackButton = () => {
    if (selectedService === "dialer") {
      if (currentStep === "services") {
        handleBackStep();
      } else {
        handleBackStep();
      }
    } else if (selectedService === "voip") {
      handleBackStep();
    } else if (selectedService === "dialervoip") {
      handleBackStep();
    } else if (selectedService === "switch") {
      handleBackStep();
    } else if (selectedService === "amazon") {
      handleBackStep();
    }
  };

  const handleNextButton = () => {
    if (selectedService === "dialer") {
      if (currentStep === "services") {
        registerUser();
        handleStep();
      } else if (currentStep === "registration") {
        registerAgentInfo();
        handleStep();
      } else if (currentStep === "noOfAgents") {
        registerServerType();
        handleStep();
      } else if (currentStep === "serverType") {
        registerDialerSupport();
        handleStep();
      } else if (currentStep === "dialerSupport") {
        registerPaymentConsent();
        handleStep();
        // } else if (currentStep === "companyInformation") {
        //   registerCompanyCertificate();
        //   handleStep();
      } else {
        handleStep();
      }
    } else if (selectedService === "voip") {
      if (currentStep === "services") {
        registerUser();
        handleStep();
      } else if (currentStep === "registration") {
        registerServiceType();
        handleStep();
      } else if (currentStep === "serviceType") {
        registerInboundOutbound();
        handleStep();
      } else if (currentStep === "serviceVolume") {
        registerSourceOfNumbers();
        handleStep();
      } else if (currentStep === "sourceOfNumbers") {
        registerCompanyInfo();
        handleStep();
      } else if (currentStep === "profOfAddress") {
        registerUserNameIp();
        handleStep();
      } else if (currentStep === "OrderSummary") {
        registerPaymentConsent();
        handleStep();
      } else {
        handleStep();
      }
    } else if (selectedService === "dialervoip") {
      if (currentStep === "services") {
        registerUser();
        handleStep();
      } else if (currentStep === "registration") {
        registerDialerServiceType();
        handleStep();
      } else if (currentStep === "dialerDetails") {
        registerUsernameVoipBalance();
        handleStep();
      } else if (currentStep === "voipDetails") {
        registerNoOfDid();
        handleStep();
      } else if (currentStep === "companyCertificate") {
        registerPaymentConsent();
        handleStep();
      } else {
        handleStep();
      }
    } else if (selectedService === "switch") {
      if (currentStep === "services") {
        registerUser();
        handleStep();
      } else if (currentStep === "registration") {
        registerSwitch();
        handleStep();
      } else if (currentStep === "switchType") {
        registerSwitchCapacity();
        handleStep();
      } else if (currentStep === "balanceAndCapacity") {
        registerPaymentConsent();
        handleStep();
      } else {
        handleStep();
      }
    }
    // else if (selectedService === "amazon") {
    //   if (currentStep === "services") {
    //     registerUser();
    //     handleStep();
    //   } else if (currentStep === "registration") {
    //     registerAmzIngfo();
    //     handleStep();
    //   } else if (currentStep === "newOrOld") {
    //     registerPaymentConsent();
    //     handleStep();
    //   } else {
    //     handleStep();
    //   }
    // }
  };

  const buttonRules = () => {
    if (selectedService === "dialer") {
      return (
        currentStep === "paymentMethod" ||
        currentStep === "OrderSummary" ||
        selectedService === "" ||
        (currentStep === "services" && regFormInfo.firstName === "") ||
        (currentStep === "services" && regFormInfo.lastName === "") ||
        (currentStep === "services" && regFormInfo.companyName === "") ||
        (currentStep === "services" && regFormInfo.address === "") ||
        (currentStep === "services" && regFormInfo.city === "") ||
        (currentStep === "services" && regFormInfo.state === "") ||
        (currentStep === "services" && regFormInfo.selectedCountry === "") ||
        (currentStep === "services" && regFormInfo.isEmailVerified === "") ||
        (currentStep === "services" && regFormInfo.isPhoneVerified === "") ||
        (currentStep === "services" && regFormInfo.skype === "") ||
        (currentStep === "registration" && regFormInfo.noOfAgents === "") ||
        (currentStep === "registration" && regFormInfo.agentCountry === "") ||
        (currentStep === "noOfAgents" && regFormInfo.serverType === "") ||
        (currentStep === "serverType" && regFormInfo.dedicatedSupport === "") ||
        (currentStep === "dialerSupport" &&
          regFormInfo.TermsConditionsAccepted === "") ||
        (currentStep === "dialerSupport" &&
          regFormInfo.personalInformationConsent === "")
      );
    } else if (selectedService === "voip") {
      return (
        currentStep === "successPage" ||
        selectedService === "" ||
        (currentStep === "services" && regFormInfo.firstName === "") ||
        (currentStep === "services" && regFormInfo.lastName === "") ||
        (currentStep === "services" && regFormInfo.companyName === "") ||
        (currentStep === "services" && regFormInfo.address === "") ||
        (currentStep === "services" && regFormInfo.city === "") ||
        (currentStep === "services" && regFormInfo.state === "") ||
        (currentStep === "services" && regFormInfo.selectedCountry === "") ||
        (currentStep === "services" && regFormInfo.isEmailVerified === "") ||
        (currentStep === "services" && regFormInfo.isPhoneVerified === "") ||
        (currentStep === "services" && regFormInfo.skype === "") ||
        (currentStep === "registration" && regFormInfo.serviceType === "") ||
        (currentStep === "serviceType" &&
          regFormInfo.monthlyTermination === "") ||
        (currentStep === "serviceType" &&
          regFormInfo.monthlyTermination === "") ||
        (currentStep === "serviceVolume" &&
          regFormInfo.sourceOfNumbers === "") ||
        (currentStep === "sourceOfNumbers" &&
          regFormInfo.FCCRegistrationNumber === "") ||
        (currentStep === "sourceOfNumbers" && regFormInfo.filerId === "") ||
        (currentStep === "sourceOfNumbers" &&
          regFormInfo.legalEntityFiles === "") ||
        (currentStep === "sourceOfNumbers" &&
          regFormInfo.mitigationDatabaseName === "") ||
        (currentStep === "companyInformation" &&
          regFormInfo.isCompanyCertificateUpdated === "") ||
        (currentStep === "companyCertificate" &&
          regFormInfo.isIdDocumentUpdated === "") ||
        (currentStep === "profOfAddress" && regFormInfo.userName === "") ||
        (currentStep === "profOfAddress" && regFormInfo.serverIp === "") ||
        (currentStep === "profOfAddress" && regFormInfo.switchType === "") ||
        (currentStep === "OrderSummary" &&
          regFormInfo.TermsConditionsAccepted === "") ||
        (currentStep === "OrderSummary" &&
          regFormInfo.personalInformationConsent === "")
      );
    } else if (selectedService === "dialervoip") {
      return (
        selectedService === "" ||
        (currentStep === "services" && regFormInfo.firstName === "") ||
        (currentStep === "services" && regFormInfo.lastName === "") ||
        (currentStep === "services" && regFormInfo.companyName === "") ||
        (currentStep === "services" && regFormInfo.address === "") ||
        (currentStep === "services" && regFormInfo.city === "") ||
        (currentStep === "services" && regFormInfo.state === "") ||
        (currentStep === "services" && regFormInfo.selectedCountry === "") ||
        (currentStep === "services" && regFormInfo.isEmailVerified === "") ||
        (currentStep === "services" && regFormInfo.isPhoneVerified === "") ||
        (currentStep === "services" && regFormInfo.skype === "") ||
        (currentStep === "registration" && regFormInfo.noOfAgents === "") ||
        (currentStep === "registration" && regFormInfo.agentCountry === "") ||
        (currentStep === "registration" && regFormInfo.serverType === "") ||
        (currentStep === "registration" && regFormInfo.campaign === "") ||
        (currentStep === "dialerDetails" && regFormInfo.userName === "") ||
        (currentStep === "dialerDetails" && regFormInfo.intialBalance === "") ||
        (currentStep === "orderSummary" && regFormInfo.paymentMethod === "") ||
        (currentStep === "voipDetails" && regFormInfo.kycRmd === "") ||
        (currentStep === "voipDetails" && regFormInfo.kycName === "") ||
        (currentStep === "voipDetails" && regFormInfo.kycDate === "") ||
        (currentStep === "voipDetails" && regFormInfo.kycSignature === "") ||
        (currentStep === "noOfDid" && regFormInfo.isIdDocumentUpdated === "") ||
        (currentStep === "companyCertificate" &&
          regFormInfo.TermsConditionsAccepted === "") ||
        (currentStep === "companyCertificate" &&
          regFormInfo.personalInformationConsent === "")
      );
    } else if (selectedService === "switch") {
      return (
        selectedService == "" ||
        (currentStep === "services" && regFormInfo.firstName === "") ||
        (currentStep === "services" && regFormInfo.lastName === "") ||
        (currentStep === "services" && regFormInfo.companyName === "") ||
        (currentStep === "services" && regFormInfo.address === "") ||
        (currentStep === "services" && regFormInfo.city === "") ||
        (currentStep === "services" && regFormInfo.state === "") ||
        (currentStep === "services" && regFormInfo.selectedCountry === "") ||
        (currentStep === "services" && regFormInfo.isEmailVerified === "") ||
        (currentStep === "services" && regFormInfo.isPhoneVerified === "") ||
        (currentStep === "services" && regFormInfo.skype === "") ||
        (currentStep === "registration" && regFormInfo.switchType === "") ||
        (currentStep === "switchType" && regFormInfo.switchLocation === "") ||
        (currentStep === "switchType" && regFormInfo.switchCapacity === "") ||
        (currentStep === "orderSummary" && regFormInfo.paymentMethod === "") ||
        (currentStep === "balanceAndCapacity" &&
          regFormInfo.TermsConditionsAccepted === "") ||
        (currentStep === "balanceAndCapacity" &&
          regFormInfo.personalInformationConsent === "")
      );
    } else if (selectedService === "amazon") {
      return (
        selectedService == "" ||
        (currentStep === "services" && regFormInfo.firstName === "") ||
        (currentStep === "services" && regFormInfo.lastName === "") ||
        (currentStep === "services" && regFormInfo.companyName === "") ||
        (currentStep === "services" && regFormInfo.address === "") ||
        (currentStep === "services" && regFormInfo.city === "") ||
        (currentStep === "services" && regFormInfo.state === "") ||
        (currentStep === "services" && regFormInfo.selectedCountry === "") ||
        (currentStep === "services" && regFormInfo.isEmailVerified === "") ||
        (currentStep === "services" && regFormInfo.isPhoneVerified === "") ||
        (currentStep === "services" && regFormInfo.skype === "") ||
        (currentStep === "registration" && regFormInfo.newToAmazon === "") ||
        (currentStep === "newOrOld" &&
          regFormInfo.TermsConditionsAccepted === "") ||
        (currentStep === "newOrOld" &&
          regFormInfo.personalInformationConsent === "")
      );
    }
  };

  const BackButtonRules = () => {
    if (selectedService === "dialer") {
      return currentStep === "OrderSummary" || currentStep === "";
    } else if (selectedService === "voip") {
      return currentStep === "successPage" || currentStep === "";
    } else if (selectedService === "dialervoip") {
      return currentStep === "";
    } else if (selectedService === "switch") {
      return currentStep === "";
    } else if (selectedService === "amazon") {
      return currentStep === "orderSummary" || currentStep === "";
    }
  };

  return (
    <>
      {(selectedService === "dialer" && currentStep === "OrderSummary") ||
      (selectedService === "dialer" && currentStep === "paymentMethod") ||
      (selectedService === "dialervoip" && currentStep === "paymentMethod") ||
      (selectedService === "dialervoip" && currentStep === "orderSummary") ||
      (selectedService === "voip" && currentStep === "successPage") ||
      (selectedService === "switch" && currentStep === "paymentMethod") ||
      (selectedService === "amazon" && currentStep === "orderSummary") ? (
        ""
      ) : (
        <MainContainer>
          <Spacer />
          <ButtonCont>
            <BackBtn
              disabled={selectedService === "" ? true : false}
              onClick={handleBackButton}
              style={{
                display:
                  (selectedService === "dialer" &&
                    currentStep === "OrderSummary") ||
                  (selectedService === "dialervoip" &&
                    currentStep === "orderSummary") ||
                  (selectedService === "voip" &&
                    currentStep === "successPage") ||
                  (selectedService === "switch" &&
                    currentStep === "orderSummary") ||
                  (selectedService === "amazon" &&
                    currentStep === "orderSummary")
                    ? "none"
                    : "",
                background: BackButtonRules() ? "#acacac" : "",
              }}
            >
              Back
            </BackBtn>
            <NextBtn
              onClick={handleNextButton}
              style={{
                display:
                  (selectedService === "dialer" &&
                    currentStep === "OrderSummary") ||
                  (selectedService === "voip" &&
                    currentStep === "successPage") ||
                  (selectedService === "dialervoip" &&
                    currentStep === "orderSummary") ||
                  (selectedService === "switch" &&
                    currentStep === "orderSummary") ||
                  (selectedService === "amazon" &&
                    currentStep === "orderSummary")
                    ? "none"
                    : "",
                background: buttonRules() ? "#acacac" : "",
              }}
              disabled={buttonRules() ? true : false}
            >
              Next
            </NextBtn>
          </ButtonCont>
        </MainContainer>
      )}

      {(selectedService === "dialer" && currentStep === "OrderSummary") ||
      (selectedService === "dialervoip" && currentStep === "paymentMethod") ||
      (selectedService === "voip" && currentStep === "successPage") ||
      (selectedService === "switch" && currentStep === "paymentMethod") ||
      (selectedService === "amazon" && currentStep === "orderSummary") ? (
        <SocialContainer>
          <SingleSocial>
            <IconSocial src="assets/images/skype.svg" />
            <SocialName>talkasiavoip</SocialName>
          </SingleSocial>

          <SingleSocial>
            <IconSocial src="assets/images/email.svg" />
            <SocialName>sales@talkasiavoip.com</SocialName>
          </SingleSocial>
        </SocialContainer>
      ) : (
        ""
      )}
    </>
  );
};

export default Footer;
