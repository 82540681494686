import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Services from "./Services/Services";

import {
  MainContainer,
  LeftCont,
  Image,
  RightCont,
  Slider,
  Padding,
} from "./Form.elements";
import { useNavigate } from "react-router-dom";

const From = () => {
  const [selectedService, setSelectedService] = useState("");
  const [currentStep, setCurrentStep] = useState("");
  const [sliderStep, setSliderStep] = useState(0);
  const [maxSliderStep, setMaxSliderStep] = useState(0);
  const [switchPrices, setSwitchPrices] = useState("");
  const [regFormInfo, setRegFormInfo] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    address: "",
    city: "",
    state: "",
    selectedCountry: "",
    email: "",
    isEmailVerified: "",
    phone: "",
    isPhoneVerified: "",
    skype: "",
    isCompanyCertificateUpdated: "",
    isIdDocumentUpdated: "",
    TermsConditionsAccepted: "",
    personalInformationConsent: "",
    paymentMethod: "",
    noOfAgents: "",
    agentCountry: "",
    serverType: "",
    dedicatedSupport: "",
    dedicatedSupport: "",
    serviceType: "",
    monthlyTermination: "",
    monthlyOrigination: "",
    sourceOfNumbers: "",
    FCCRegistrationNumber: "",
    filerId: "",
    legalEntityFiles: "",
    mitigationDatabaseName: "",
    userName: "",
    serverIp: "",
    switchType: "",
    intialBalance: "",
    noOfDid: "",
    newToAmazon: "",
    switchHosted: "",
    oneTimeInstallation: "",
    fileName: "",
    IdCardFrontName: "",
    setIdCardBackName: "",
    setIdName: "",
  });
  const [cartItem, setCartItem] = useState({
    cartTotal: 0,
  });

  const handleChange = (event) => {
    setSelectedService(event.target.value);
    // setCurrentStep("services");
  };

  const handleBackStep = () => {
    setSliderStep((prevStep) => prevStep - 1);
    if (selectedService === "dialer") {
      switch (currentStep) {
        case "services":
          setCurrentStep("");
          break;
        case "registration":
          setCurrentStep("services");
          break;
        case "noOfAgents":
          setCurrentStep("registration");
          break;
        case "serverType":
          setCurrentStep("noOfAgents");
          break;
        case "dialerSupport":
          setCurrentStep("serverType");
          break;
        default:
          setCurrentStep(currentStep);
      }
    } else if (selectedService === "voip") {
      switch (currentStep) {
        case "services":
          setCurrentStep("");
          break;
        case "registration":
          setCurrentStep("services");
          break;
        case "serviceType":
          setCurrentStep("registration");
          break;
        case "sourceOfNumbers":
          setCurrentStep("serviceVolume");
          break;
        case "companyInformation":
          setCurrentStep("sourceOfNumbers");
          break;
        case "serviceVolume":
          setCurrentStep("serviceType");
          break;
        case "companyCertificate":
          setCurrentStep("companyInformation");
          break;
        case "profOfAddress":
          setCurrentStep("companyCertificate");
          break;
        case "OrderSummary":
          setCurrentStep("profOfAddress");
          break;
        default:
          setCurrentStep(currentStep);
      }
    } else if (selectedService === "dialervoip") {
      switch (currentStep) {
        case "services":
          setCurrentStep("");
          break;
        case "registration":
          setCurrentStep("services");
          break;
        case "dialerDetails":
          setCurrentStep("registration");
          break;
        case "voipDetails":
          setCurrentStep("dialerDetails");
          break;
        case "noOfDid":
          setCurrentStep("voipDetails");
          break;
        case "companyCertificate":
          setCurrentStep("noOfDid");
          break;
        case "orderSummary":
          setCurrentStep("companyCertificate");
          break;
        default:
          setCurrentStep(currentStep);
      }
    } else if (selectedService === "switch") {
      switch (currentStep) {
        case "services":
          setCurrentStep("");
          break;
        case "registration":
          setCurrentStep("services");
          break;
        case "switchType":
          setCurrentStep("registration");
          break;
        case "balanceAndCapacity":
          setCurrentStep("switchType");
          break;

        default:
          setCurrentStep(currentStep);
      }
    } else if (selectedService === "amazon") {
      switch (currentStep) {
        case "services":
          setCurrentStep("");
          break;
        case "registration":
          setCurrentStep("services");
          break;
        case "newOrOld":
          setCurrentStep("registration");
          break;

        default:
          setCurrentStep(currentStep);
      }
    }
  };

  const navigate = useNavigate();

  const handleStep = () => {
    setSliderStep((prevStep) => prevStep + 1);
    if (selectedService === "dialer") {
      navigate("/dialer");
    } else if (selectedService === "voip") {
      navigate("/voip");
    } else if (selectedService === "dialervoip") {
      navigate("/dialervoip");
    } else if (selectedService === "switch") {
      navigate("/softswitch");
    }
  };

  const handleFormUpdate = (event) => {
    // console.log(event);
    let value = event.target.value;
    // console.log("name on back", name);
    setRegFormInfo((prevState) => ({
      ...prevState,
      [event.target.name]: value,
    }));
  };



  return (
    <MainContainer>
      <LeftCont>
        <Image src="assets/images/bg.svg" />
      </LeftCont>
      <RightCont>
        <Header cartItem={cartItem} />

        <Padding>
          <Slider type="range" min="0" max={maxSliderStep} value={sliderStep} />
        </Padding>

        <Services
          handleChange={handleChange}
          handleFormUpdate={handleFormUpdate}
          regFormInfo={regFormInfo}
        />

        <Footer
          handleStep={handleStep}
          handleBackStep={handleBackStep}
          currentStep={currentStep}
          selectedService={selectedService}
          regFormInfo={regFormInfo}
          CartItemInfo={cartItem}
        />
      </RightCont>
    </MainContainer>
  );
};

export default From;
