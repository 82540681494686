const SERVER_URL = "https://register.talkasiavoip.com/public/api";
// const SERVER_URL = "http://127.0.0.1:8000/api";

export const SEND_OTP = `${SERVER_URL}/sendotp`;

export const VERIFY_OTP = `${SERVER_URL}/verifyotp`;

export const REGISTER_USER = `${SERVER_URL}/register`;

export const REGISTER_AGENT_INFO = `${SERVER_URL}/registeragentinfo`;

export const REGISTER_SERVER_TYPE = `${SERVER_URL}/registerservertype`;

export const REGISTER_DIALER_SUPPORT = `${SERVER_URL}/registerdialersupport`;

export const REGISTER_PAYMENT = `${SERVER_URL}/registerpaymentconsent`;

export const REGISTER_COMPANY_CERTIFICATE = `${SERVER_URL}/registercompanycertificate`;

export const REGISTER_ID_PROOF = `${SERVER_URL}/registeridproof`;

export const REGISTER_ID_TYPE = `${SERVER_URL}/registeridtype`;

export const CALCULATE_TOTALS = `${SERVER_URL}/calculatetotals`;

export const REGISTER_INVOICE = `${SERVER_URL}/registerinvoice`;

export const REGISTER_SERVICE_TYPE = `${SERVER_URL}/registerservicetype`;

export const REGISTER_INBOUND_OUTBOUND_VOL = `${SERVER_URL}/inboundoutboundvol`;

export const REGISTER_SOURCE_OF_NUMBERS = `${SERVER_URL}/sourceofnumbers`;

export const REGISTER_USERNAME_IP = `${SERVER_URL}/usernameip`;

export const REGISTER_COMPANY_INFO = `${SERVER_URL}/companyinformation`;

export const REGISTER_DIALER_SERVER_TYPE = `${SERVER_URL}/registerdialerserver`;

export const REGISTER_USERNAME_VOIP_BALANCE = `${SERVER_URL}/registerusernamevoip`;

export const REGISTER_NO_OF_DID = `${SERVER_URL}/registernoofdid`;

export const REGISTER_AMZ_INFO = `${SERVER_URL}/registeramzinfo`;

export const REGISTER_SWITCH = `${SERVER_URL}/registerswitch`;

export const REGISTER_SWITCH_CAPACITY = `${SERVER_URL}/registerswitchcapacity`;

export const SEND_CONFIRMATION_EMAIL = `${SERVER_URL}/sendconfirmationemail`;
