import React from "react";
import {
  MainContainer,
  Logo,
  CartCont,
  Icon,
  Price,
  FB,
  Insta,
} from "./Header.elements";

const Header = ({ cartItem }) => {
  return (
    <MainContainer>
      <FB href="https://www.talkasiavoip.com" target="_blank">
        <Logo src="assets/images/logo.svg" />
      </FB>
      <CartCont>
        {/* <Icon src="assets/images/cart-icon.svg" />
        <Price>${cartItem.cartTotal}</Price> */}
        <FB href="https://www.facebook.com/TalkAsiaTech/" target="_blank">
          <Icon src="assets/images/fb.svg" />
        </FB>
        <Insta
          href="https://www.linkedin.com/company/talkasiavoip-llc/"
          target="_blank"
        >
          <Icon src="assets/images/linkdin.svg" />
        </Insta>
      </CartCont>
    </MainContainer>
  );
};

export default Header;
