import { RouterProvider, createBrowserRouter } from "react-router-dom";
import GlobalStyle from "./globalStyles";
import HomePage from "./pages/HomePage";
import DialerForm from "./components/Form/DialerForm";
import VoipForm from "./components/Form/VoipForm";
import DialerVoipForm from "./components/Form/DialerVoipForm";
import SoftSwitchForm from "./components/Form/SoftSwitchForm";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/dialer",
    element: <DialerForm />,
  },
  {
    path: "/voip",
    element: <VoipForm />,
  },
  {
    path: "/dialervoip",
    element: <DialerVoipForm />,
  },
  {
    path: "/softswitch",
    element: <SoftSwitchForm />,
  },
]);

function App() {
  return (
    <div className="app">
      <GlobalStyle />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
