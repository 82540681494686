import styled from "styled-components";
import ReactFlagsSelect from "react-flags-select";

export const MainContainer = styled.div`
  max-width: 1050px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 470px) {
  }
`;

export const Heading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.24px;
  text-align: center;
  color: #000000;
  margin-bottom: 0px;
  margin-top: 0px;
`;

export const SubHeading = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #727272;
  margin: 0;
  padding: 0;
  margin-bottom: 38px;
`;

export const InputCont = styled.div`
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 38px;

  @media (max-width: 470px) {
    width: 90%;
  }
`;

export const Title = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.24px;
  color: #000000;
  margin-bottom: 10px;
`;

export const Input = styled.input`
  width: 100%;
  height: 38px;
  border: 1px solid #36a369;
  box-sizing: border-box;
  border-radius: 0px;
  outline: none;
  padding: 0px 19px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #868686;
  margin-bottom: 10px;

  ::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #868686;
  }
`;

export const InputSelect = styled(ReactFlagsSelect)`
  width: 100%;
  height: 38px;
  border: 1px solid #36a369;
  box-sizing: border-box;
  border-radius: 0px;
  outline: none;

  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #868686;
  margin-bottom: 10px;

  button {
    border: none;
  }

  ::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #868686;
  }
`;

export const VerifyCont = styled.div`
  max-width: 550px;
  width: 100%;
  display: flex;
`;

export const InputEmail = styled.input.attrs({ type: "email" })`
  max-width: 390px;
  width: 100%;
  height: 50px;
  border: 1px solid #36a369;
  box-sizing: border-box;
  border-radius: 0px;
  outline: none;
  padding: 0px 19px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: #868686;
  margin-bottom: 18px;
  border-right: none;

  ::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    color: #868686;
  }
`;

export const EmailVerifyButton = styled.button`
  width: 160px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff369c;
  border: none;
  outline: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  align-items: center;
  color: #ffffff;
  cursor: pointer;

  :hover {
    background: #f73698;
  }
`;

export const Ring = styled.div`
  width: 21px;
  height: 21px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  margin-right: 7px;
`;

export const InputPhone = styled.input.attrs({ type: "tel" })`
  max-width: 390px;
  width: 100%;
  height: 50px;
  border: 1px solid #36a369;
  box-sizing: border-box;
  border-radius: 0px;
  outline: none;
  padding: 0px 19px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: #868686;
  margin-bottom: 18px;
  border-right: none;

  ::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    color: #868686;
  }
`;

export const PhoneVerifyButton = styled.button`
  width: 160px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff369c;
  border: none;
  outline: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  align-items: center;
  color: #ffffff;
  cursor: pointer;

  :hover {
    background: #f73698;
  }
`;

export const Verified = styled.div`
  width: 160px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #36a369;
  border: none;
  outline: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  align-items: center;
  color: #ffffff;
`;

export const VerifiedImage = styled.img`
  margin-right: 7px;
`;

// export const div = styled.div``;
