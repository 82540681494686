import React, { useState } from "react";
import {
  MainContainer,
  Heading,
  SubHeading,
  InputCont,
  Input,
  Title,
} from "./VoipScreenFour.elements";

const VoipScreenFour = ({ handleFormUpdate, regFormInfo }) => {
  const [checked, setChecked] = useState(false);
  const [service, setService] = useState("");
  const [selected, setSelected] = useState("");
  const [verified, setVerified] = useState(true);

  const handleCheck = (event) => {
    console.log(event.target.value);
    setService(event.target.value);
    setChecked(!checked);
  };

  const updateRegInfo = (event) => {
    handleFormUpdate(event);
    console.log("country:", selected);
    // console.log("name:", propName);
    // console.log("value:", value);
  };

  const updateCountryInfo = (event) => {
    handleFormUpdate(event);
    console.log("country:", selected);
    // console.log("name:", propName);
    // console.log("value:", value);
  };

  return (
    <MainContainer>
      <Heading>Fill Up Company Details</Heading>
      {/* <SubHeading>Are you already a TalkAsia VoIP Service Provider?</SubHeading> */}

      <InputCont>
        <Title>Your Company Information</Title>
        <Input
          type="text"
          name="FCCRegistrationNumber"
          placeholder="FCC registation number (FRN)"
          onChange={updateRegInfo}
          value={regFormInfo.FCCRegistrationNumber}
        />
        <Input
          type="text"
          name="filerId"
          placeholder="499 A Filer ID"
          onChange={updateRegInfo}
          value={regFormInfo.filerId}
        />

        <Input
          type="text"
          name="legalEntityFiles"
          placeholder="Legal entity that files the 499 worksheet"
          onChange={updateRegInfo}
          value={regFormInfo.legalEntityFiles}
        />
        <Input
          type="text"
          name="mitigationDatabaseName"
          placeholder="Robocall mitigation database business name"
          onChange={updateRegInfo}
          value={regFormInfo.mitigationDatabaseName}
        />
      </InputCont>
    </MainContainer>
  );
};

export default VoipScreenFour;
