import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 64px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 60px;
  box-sizing: border-box;
  background: #edfff5;
  @media (max-width: 980px) {
    padding: 0px 20px;
  }
`;

export const Logo = styled.img`
  width: 60px;
`;

export const CartCont = styled.div`
  width: 181px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.img`
  width: 28px;
`;

export const Price = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  letter-spacing: -0.24px;
  color: #ffffff;
  margin-left: 17px;
`;

export const FB = styled.a`
  margin-right: 10px;
`;

export const Insta = styled.a``;

// export const div = styled.div``;
