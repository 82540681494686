import React, { useState, useEffect, useRef } from "react";
import {
  MainContainer,
  SuccessImg,
  UserName,
  ThankYou,
  InfoText,
  BankDetails,
  BankInfo,
  BankInfoHeading,
  Note,
  Invert,
  FinishButton,
  Popup,
  PopupInner,
  PopupClose,
  PopupTitle,
} from "./Success.elements";
import {
  Heading,
  SubHeading,
  OptionsCont,
  Option,
  SingleOption,
  OptionLabel,
  Title,
  LabelImage,
  ChekCont,
  CheckBox,
  CheckLabel,
  SingleCheck,
  SubmitCont,
  SubmitButton,
  BankTransfer,
  PayPal,
} from "./Payment.elements";
import axios from "axios";
import { SEND_CONFIRMATION_EMAIL } from "../Endpoints";
import { useNavigate } from "react-router-dom";
import { REGISTER_INVOICE } from "../Endpoints";

const Success = ({ setPaymentMethod, regFormInfo, handleStep }) => {


  const [showBankDetails, setShowBankDetails] = useState(false);
  const [showPaypalDetails, setShowPaypalDetails] = useState(false);
  const navigate = useNavigate();

  return (
    <MainContainer>
      <SuccessImg src="assets/images/success-img.svg" />
      <UserName>
        {regFormInfo.firstName} {regFormInfo.lastName}
      </UserName>
      <ThankYou>Thank you for your time!</ThankYou>
      {showBankDetails ? (
        <Popup>
          <PopupInner>
            <PopupClose onClick={() => setShowBankDetails(false)}>X</PopupClose>
            {/* <PopupTitle>Our Bank Account Details</PopupTitle> */}
            <Note>Please transfer the Funds to below account</Note>
            <BankDetails>
              <BankInfo>
                <BankInfoHeading>Account Title:</BankInfoHeading>TalkAsia
                Technologies{" "}
              </BankInfo>
              <BankInfo>
                <BankInfoHeading>Account No:</BankInfoHeading>01-7294022-01{" "}
              </BankInfo>
              <BankInfo>
                <BankInfoHeading>Bank Name:</BankInfoHeading>Standard Chartered
                Bank Pakistan{" "}
              </BankInfo>
              <BankInfo>
                <BankInfoHeading>IBAN:</BankInfoHeading>PK37SCBL0000001729402201{" "}
              </BankInfo>
            </BankDetails>
            <FinishButton onClick={() => setShowBankDetails(false)}>
              Close
            </FinishButton>
          </PopupInner>
        </Popup>
      ) : null}
      {showPaypalDetails ? (
        <Popup>
          <PopupInner>
            <PopupClose onClick={() => setShowPaypalDetails(false)}>
              X
            </PopupClose>
            {/* <PopupTitle>Our Bank Account Details</PopupTitle> */}
            <Note>Please transfer the Funds to below account</Note>
            <BankDetails>
              <BankInfo>
                <BankInfoHeading>Paypal ID:</BankInfoHeading>
                tav@talkasiavoip.com
              </BankInfo>
            </BankDetails>
            <FinishButton onClick={() => setShowPaypalDetails(false)}>
              Close
            </FinishButton>
          </PopupInner>
        </Popup>
      ) : null}
      <InfoText>
        We will contact you shortly at the following email{" "}
        <Invert>{regFormInfo.email} </Invert>
        <br />
        Click on following buttons to get our payment details:
      </InfoText>
      <OptionsCont>
        <SingleOption for="paypal" onClick={() => setShowPaypalDetails(true)}>
          <BankTransfer>
            <LabelImage src="assets/images/payment-icon-1.svg" />
          </BankTransfer>
        </SingleOption>

        <SingleOption
          for="bankTransfer"
          onClick={() => setShowBankDetails(true)}
        >
          <BankTransfer>
            <LabelImage src="assets/images/payment-icon-2.svg" />
          </BankTransfer>
        </SingleOption>
      </OptionsCont>
      <FinishButton onClick={() => navigate("/")}>Finish</FinishButton>
    </MainContainer>
  );
};

export default Success;
