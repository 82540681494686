import React, { useState } from "react";
import {
  MainContainer,
  Heading,
  SubHeading,
  OptionsCont,
  Option,
  SingleOption,
  OptionLabel,
  Title,
} from "./DialerScreenTwo.elements";

const DialerScreenTwo = ({ handleFormUpdate, handleCartItem, regFormInfo }) => {
  const [selected, setSelected] = useState("");
  const [checked, setChecked] = useState(false);
  const [service, setService] = useState("");

  const handleCheck = (event) => {
    handleFormUpdate(event);
    handleCartItem(event);
  };

  return (
    <MainContainer>
      <Heading>Fill Up Service Details</Heading>
      {/* <SubHeading>Are you already a TalkAsia VoIP Service Provider?</SubHeading> */}

      <OptionsCont>
        <Title>Server Type</Title>
        <SingleOption
          style={{ background: service === "5" ? "#E5FFF1" : "" }}
          for="serverType"
        >
          <Option
            type="radio"
            name="serverType"
            id="cloud"
            value="cloud"
            onChange={handleCheck}
            checked={regFormInfo.serverType === "cloud" ? true : false}
          />
          <OptionLabel for="cloud">
            Cloud Based 3$ / Per User / Month
          </OptionLabel>
        </SingleOption>
        <SingleOption
          style={{ background: service === "10" ? "#E5FFF1" : "" }}
          for="serverType"
        >
          <Option
            type="radio"
            name="serverType"
            id="dedicated"
            value="dedicated"
            onChange={handleCheck}
            checked={regFormInfo.serverType === "dedicated" ? true : false}
          />
          <OptionLabel for="dedicated">
            Dedicated Server 10$ / Per User / Month
          </OptionLabel>
        </SingleOption>
      </OptionsCont>
    </MainContainer>
  );
};

export default DialerScreenTwo;
