import React, { useState } from "react";
import {
  MainContainer,
  Heading,
  SubHeading,
  OptionsCont,
  Option,
  SingleOption,
  OptionLabel,
} from "./Services.elements";

const Services = ({ handleChange, handleFormUpdate, regFormInfo }) => {
  const [checked, setChecked] = useState(false);
  const [service, setService] = useState("");

  const handleCheck = (event) => {
    setService(event.target.value);
    setChecked(!checked);
    handleChange(event);
    handleFormUpdate(event);
  };

  return (
    <MainContainer>
      <Heading>What Services Are You Looking For?</Heading>
      {/* <SubHeading>Are you already a TalkAsia VoIP Service Provider?</SubHeading> */}

      <OptionsCont>
        <SingleOption
          style={{ background: service === "dialer" ? "#E5FFF1" : "" }}
          for="service"
        >
          <Option
            type="checkbox"
            name="service"
            id="dialer"
            value="dialer"
            onChange={handleCheck}
            checked={regFormInfo.service === "dialer" ? true : false}
          />
          <OptionLabel for="dialer">Dialer Only</OptionLabel>
        </SingleOption>

        <SingleOption
          style={{ background: service === "voip" ? "#E5FFF1" : "" }}
          for="service"
        >
          <Option
            type="radio"
            name="service"
            id="voip"
            value="voip"
            onChange={handleCheck}
            checked={regFormInfo.service === "voip" ? true : false}
          />
          <OptionLabel for="voip">US/Canada VoIP Only</OptionLabel>
        </SingleOption>

        <SingleOption
          style={{ background: service === "dialervoip" ? "#E5FFF1" : "" }}
          for="service"
        >
          <Option
            type="radio"
            name="service"
            id="dialervoip"
            value="dialervoip"
            onChange={handleCheck}
            checked={regFormInfo.service === "dialervoip" ? true : false}
          />
          <OptionLabel for="dialervoip">Dialer & VoIP</OptionLabel>
        </SingleOption>

        <SingleOption
          style={{ background: service === "switch" ? "#E5FFF1" : "" }}
          for="service"
        >
          <Option
            type="radio"
            name="service"
            id="switch"
            value="switch"
            onChange={handleCheck}
            checked={regFormInfo.service === "switch" ? true : false}
          />
          <OptionLabel for="switch">Hosted SoftSwitch</OptionLabel>
        </SingleOption>

        {/* <SingleOption
          style={{ background: service === "amazon" ? "#E5FFF1" : "" }}
          for="service"
        >
          <Option
            type="radio"
            name="service"
            id="amazon"
            value="amazon"
            onChange={handleCheck}
            checked={regFormInfo.service === "amazon" ? true : false}
          />
          <OptionLabel for="amazon">Amazon Services</OptionLabel>
        </SingleOption> */}
      </OptionsCont>
    </MainContainer>
  );
};

export default Services;
