import styled from "styled-components";

export const MainContainer = styled.div`
  /* max-width: 1440px; */
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  @media (max-width: 980px) {
  }
`;

export const LeftCont = styled.div`
  max-width: 615px;
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  /* 
  background: url("assets/images/bg.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #36a369;
  background-attachment: fixed; */
  /* position: fixed; */
  background-color: #36a369;
  @media (max-width: 1280px) {
    display: none;
  }
`;

export const Image = styled.img`
  max-width: 80%;
  max-height: 80%;
  position: fixed;
  margin-top: 40px;
`;

export const RightCont = styled.div`
  min-height: 100vh;
  height: 100%;
  flex-grow: 1;
`;

export const Padding = styled.div`
  max-width: 547px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 38px;
  margin-top: 38px;
`;

export const Slider = styled.input.attrs({ type: "range" })`
  /* background: #36a369; */
  background: linear-gradient(
    to right,
    #36a369 0%,
    #36a369 100%,
    #bee7d1 50%,
    #bee7d1 100%
  );
  height: 6px;
  width: 100%;
  outline: none;
  transition: all 450ms ease-in;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #36a369;
    border: 3px solid #fff;
    /* box-shadow: 0px 10px 20px 0px rgba(16, 213, 194, 0.7); */

    position: relative;
    transition: all 0.2s;
    &:hover {
      background: #36a369;
    }
    &:active {
      /* cursor: grabbing; */
      background: #36a369;
    }
  }

  @media (max-width: 470px) {
    display: none;
  }
`;

// export const div = styled.div``;
// export const div = styled.div``;
// export const div = styled.div``;
// export const div = styled.div``;
