import styled from "styled-components";

export const MainContainer = styled.div`
  height: 118px;
  max-width: 556px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0px 60px; */
  border-top: 3px solid #eaeaea;
  box-sizing: border-box;


  @media (max-width: 480px) {
    flex-direction: column-reverse;
    justify-content: space-evenly;
  }
`;

export const FgLogo = styled.img`
  width: 164px;
`;

export const Spacer = styled.div`
  width: 209px;
`;

export const ButtonCont = styled.div`

  display: flex;
  align-items: center;
`;

export const BackBtn = styled.button`
  width: 86px;
  height: 36px;
  background: #36a369;
  border: none;

  border-radius: 64.5px 42px 42px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  margin-right: 6px;
  cursor: pointer;
`;

export const NextBtn = styled.button`
  width: 86px;
  height: 36px;
  background: #36a369;
  border: none;
  border-radius: 42px 0px 64.5px 42px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px; 
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;

export const SocialContainer = styled.div`
max-width: 556px;
  width: 100%;
  margin: 0 auto;
  border-top: 3px solid #eaeaea;
display:flex;
align-items:center;
justify-content:space-between;

 @media (max-width: 470px) {
  
    max-width:450px;
    justify-content:space-around;
  }

`;

export const SingleSocial = styled.div`
display:flex;

`;

export const IconSocial = styled.img`
margin-right:12px;
`;

export const SocialName = styled.h6`
font-family: 'Poppins';
font-style: normal;
font-weight: normal; 
font-size: 15px;
line-height: 22px;

color: #272727;
`;

export const FgLink = styled.a`

`;