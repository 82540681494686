import React, { useState } from "react";
import {
  MainContainer,
  Heading,
  SubHeading,
  OptionsCont,
  Option,
  SingleOption,
  OptionLabel,
  Title,
  CapacityCont,
  PriceCont,
  Price,
  CapacityAndPrice,
} from "./SwitchScreenTwo.elements";

const SwitchScreenTwo = ({ handleFormUpdate, regFormInfo, setSwitchPrices, switchPrices }) => {
  const [service, setService] = useState("");
  const [capacity, setCapacity] = useState("");
  

  

  // const switchPrices = {
  //   "500cc": "",
  //   "1000cc": "",
  //   "1500cc": "",
  //   "2000cc": "",
  //   // usa: { "500cc": 150, "1000cc": 180, "1500cc": 240, "2000cc": 300 },
  //   // singapore: { "500cc": 200, "1000cc": 250, "1500cc": 300, "2000cc": 500 },
  // };

  const handleCheck = async (event) => {
    await setService(event.target.value);
    await handleFormUpdate(event);
     if (event.target.value == "germany") {
      setSwitchPrices (
        <>
          <Price>100$ / Month</Price>
          <Price>140$ / Month</Price>
          <Price>160$ / Month</Price>
          <Price>200$ / Month</Price>
        </>
      );
    } else if (event.target.value == "usa") {
      setSwitchPrices (
        <>
          <Price>150$ / Month</Price>
          <Price>180$ / Month</Price>
          <Price>240$ / Month</Price>
          <Price>300$ / Month</Price>
        </>
      );
    } else if (event.target.value == "singapore") {
      setSwitchPrices (
        <>
          <Price>200$ / Month</Price>
          <Price>250$ / Month</Price>
          <Price>300$ / Month</Price>
          <Price>500$ / Month</Price>
        </>
      );
    }
  };

  const handleCapacity = (event) => {
    setCapacity(event.target.value);
    handleFormUpdate(event);
  };

  

  return (
    <MainContainer>
      <Heading>Fill Up Service Details</Heading>
      {/* <SubHeading>Are you already a TalkAsia VoIP Service Provider?</SubHeading> */}
      <OptionsCont>
        <Title>Server Location</Title>
        <SingleOption
          style={{
            background: service == "yes" ? "#E5FFF1" : "",
          }}
          for="newAmazon"
        >
          <Option
            type="radio"
            name="switchLocation"
            id="germany"
            value="germany"
            onChange={handleCheck}
            checked={regFormInfo.switchLocation === "germany" ? true : false}
          />
          <OptionLabel for="germany">Germany</OptionLabel>
        </SingleOption>
        <SingleOption
          style={{ background: service == "no" ? "#E5FFF1" : "" }}
          for="old"
        >
          <Option
            type="radio"
            name="switchLocation"
            id="usa"
            value="usa"
            onChange={handleCheck}
            checked={regFormInfo.switchLocation === "usa" ? true : false}
          />
          <OptionLabel for="usa">USA</OptionLabel>
        </SingleOption>

        <SingleOption
          style={{ background: service == "no" ? "#E5FFF1" : "" }}
          for="old"
        >
          <Option
            type="radio"
            name="switchLocation"
            id="singapore"
            value="singapore"
            onChange={handleCheck}
            checked={regFormInfo.switchLocation === "singapore" ? true : false}
          />
          <OptionLabel for="singapore">Singapore</OptionLabel>
        </SingleOption>
      </OptionsCont>

      <OptionsCont style={{marginBottom:"0px"}}>
      <Title>What capacity do you need?</Title>
      </OptionsCont>

      <CapacityAndPrice>
        <CapacityCont>
          <SingleOption
            style={{ background: service == "no" ? "#E5FFF1" : "" }}
            for="old"
          >
            <Option
              type="radio"
              name="switchCapacity"
              id="500cc"
              value="500cc"
              onChange={handleCapacity}
              checked={regFormInfo.switchCapacity === "500cc" ? true : false}
            />
            <OptionLabel for="500cc">500cc with 50 CPS</OptionLabel>
          </SingleOption>

          <SingleOption
            style={{ background: service == "no" ? "#E5FFF1" : "" }}
            for="old"
          >
            <Option
              type="radio"
              name="switchCapacity"
              id="1000cc"
              value="1000cc"
              onChange={handleCapacity}
              checked={regFormInfo.switchCapacity === "1000cc" ? true : false}
            />
            <OptionLabel for="1000cc">1000cc with 100 CPS</OptionLabel>
          </SingleOption>

          <SingleOption
            style={{ background: service == "no" ? "#E5FFF1" : "" }}
            for="old"
          >
            <Option
              type="radio"
              name="switchCapacity"
              id="1500cc"
              value="1500cc"
              onChange={handleCapacity}
              checked={regFormInfo.switchCapacity === "1500cc" ? true : false}
            />
            <OptionLabel for="1500cc">1500cc with 150 CPS</OptionLabel>
          </SingleOption>

          <SingleOption
            style={{ background: service == "no" ? "#E5FFF1" : "" }}
            for="old"
          >
            <Option
              type="radio"
              name="switchCapacity"
              id="2000cc"
              value="2000cc"
              onChange={handleCapacity}
              checked={regFormInfo.switchCapacity === "2000cc" ? true : false}
            />
            <OptionLabel for="2000cc">2000cc with 190 CPS</OptionLabel>
          </SingleOption>
        </CapacityCont>

        {/* <PriceCont>{handlePrices()}</PriceCont> */}
        <PriceCont>{switchPrices}</PriceCont>
      </CapacityAndPrice>
    </MainContainer>
  );
};

export default SwitchScreenTwo;
