import React, { useState, useRef } from "react";
import {
  MainContainer,
  Heading,
  SubHeading,
  ExtraInfo,
  InputCont,
  Title,
  FileUpload,
  ImgCont,
  UploadInfo,
  UploadImg,
  UploadName,
  FileUploadButton,
  UploadButton,
  UploadImage,
  Error,
} from "./VoipScreenFive.elements";

import axios from "axios";
import { REGISTER_COMPANY_CERTIFICATE } from "../../Endpoints";

const VoipScreenFive = ({
  handleFormUpdate,
  regFormInfo,
  isCompanyCertificateUpdated,
  setCompanyCertificateeName,
}) => {
  const [checked, setChecked] = useState(false);
  const [service, setService] = useState("");
  const [selected, setSelected] = useState("");
  // const [fileName, setFileName] = useState("No File Choosen");
  const [error, setError] = useState("");
  const hiddenFileUpload = useRef(null);

  const handleCheck = (event) => {
    console.log(event.target.value);
    setService(event.target.value);
    setChecked(!checked);
  };

  const handleUploadButtonClick = () => {
    hiddenFileUpload.current.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    console.log(file);
    setCompanyCertificateeName(file.name);
  };

  const registerCompanyCertificate = async (event) => {
    setCompanyCertificateeName(event.target.files[0].name);
    if (
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "application/pdf" ||
      event.target.files[0].type === "image/png"
    ) {
      setError("");
      const data = new FormData();
      data.append("companyCertificate", event.target.files[0]);
      data.append("email", regFormInfo.email);

      const response = await axios.post(REGISTER_COMPANY_CERTIFICATE, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);
      if (response.data.code === "201") {
        isCompanyCertificateUpdated("yes");
      }
    } else {
      setError("Please upload PDF or Image file");
    }
  };

  return (
    <MainContainer>
      <Heading>Verify Your Company</Heading>
      <SubHeading>
        To avoid delays when verifying account, please make sure
      </SubHeading>
      <ExtraInfo>
        Document should be in good condition and clearly visible.
      </ExtraInfo>

      <Title>Upload Here Your Company Certificate</Title>
      <InputCont>
        <FileUpload>
          <UploadInfo>
            <UploadImg src="assets/images/file-upload-icon.svg" />
            <UploadName>
              {regFormInfo.fileName == ""
                ? "No File Choosen"
                : regFormInfo.fileName}
            </UploadName>
          </UploadInfo>

          <UploadButton onClick={handleUploadButtonClick}>
            Choose File
          </UploadButton>
          <Error> {error}</Error>
          <FileUploadButton
            type="file"
            name="companyCertificate"
            ref={hiddenFileUpload}
            onChange={registerCompanyCertificate}
          />
        </FileUpload>

        <ImgCont>
          <UploadImage src="assets/images/file-upload-image.svg" />
        </ImgCont>
      </InputCont>
    </MainContainer>
  );
};

export default VoipScreenFive;
