import React, { useEffect, useState } from "react";
import {
  MainContainer,
  Heading,
  SubHeading,
  OptionsCont,
  Option,
  SingleOption,
  OptionLabel,
  CustomAgent,
  Country,
  Title,
  ServerTypeCont,
  VoipUsername,
} from "./DialerVoipScreenTwo.elements";

const DialerVoipScreenTwo = ({ handleFormUpdate, regFormInfo }) => {
  const [serviceName, setServiceName] = useState("");
  const [checked, setChecked] = useState(false);
  const [service, setService] = useState("");
  const [customAgentNum, setCustomAgentNum] = useState(false);

  // const handleCheck = (event) => {
  //   setServiceName(event.target.name);
  //   setService(event.target.value);
  //   if (event.target.value === "custom") setCustomAgentNum(true);
  //   console.log("service", event.target.value);
  //   handleFormUpdate(event);
  // };

  const handleCheck = (event) => {
    if (event.target.value === "custom") {
      setCustomAgentNum(true);
      event.target.value = "";
    } else {
      setCustomAgentNum(false);
    }

    handleFormUpdate(event);
  };

  const handleCustomAgent = (event) => {
    handleFormUpdate(event);
  };

  const [price, setPrice] = useState(0.0055);

  useEffect(() => {
    const calculatePrice = () => {
      console.log(regFormInfo.noOfAgents);
      if (regFormInfo.noOfAgents <= 10) {
        setPrice(0.0055);
      } else if (regFormInfo.noOfAgents <= 20) {
        setPrice(0.005);
      } else {
        setPrice(0.0045);
      }
    };
    calculatePrice();
  }, [regFormInfo]);

  return (
    <MainContainer>
      <Heading>VoIP Details</Heading>
      {/* <SubHeading>Are you already a TalkAsia VoIP Service Provider?</SubHeading> */}

      <SubHeading>
        Voip Price: {price}$/min with 6/6 billing increment
      </SubHeading>
      <VoipUsername
        id="voipUsername"
        placeholder="VoIP Username You Want"
        name="userName"
        onChange={(event) => handleFormUpdate(event)}
        value={regFormInfo.userName}
      />

      <OptionsCont>
        <Title>Initial VoIP Balance You Want To Start With</Title>
        <SingleOption for="intialBalance">
          <Option
            type="radio"
            name="intialBalance"
            id="50"
            value="50"
            onChange={handleCheck}
            checked={regFormInfo.intialBalance === "50" ? true : false}
          />
          <OptionLabel for="50">50$</OptionLabel>
        </SingleOption>
        <SingleOption for="intialBalance">
          <Option
            type="radio"
            name="intialBalance"
            id="100"
            value="100"
            onChange={handleCheck}
            checked={regFormInfo.intialBalance === "100" ? true : false}
          />
          <OptionLabel for="100">100$</OptionLabel>
        </SingleOption>
        <SingleOption for="intialBalance">
          <Option
            type="radio"
            name="intialBalance"
            id="150"
            value="150"
            onChange={handleCheck}
            checked={regFormInfo.intialBalance === "150" ? true : false}
          />
          <OptionLabel for="150">150$</OptionLabel>
        </SingleOption>
        <SingleOption for="intialBalance">
          <Option
            type="radio"
            name="intialBalance"
            id="200"
            value="200"
            onChange={handleCheck}
            checked={regFormInfo.intialBalance === "200" ? true : false}
          />
          <OptionLabel for="200">200$</OptionLabel>
        </SingleOption>

        <SingleOption for="intialBalance">
          <Option
            type="radio"
            name="intialBalance"
            id="500"
            value="500"
            onChange={handleCheck}
            checked={regFormInfo.intialBalance === "500" ? true : false}
          />
          <OptionLabel for="500">500$</OptionLabel>
        </SingleOption>

        <SingleOption
          style={{
            width: "100px",
            marginRight: "0px",
          }}
          for="intialBalance"
        >
          <Option
            type="radio"
            name="intialBalance"
            id="custom"
            value="custom"
            onChange={handleCheck}
            checked={customAgentNum}
          />
          <OptionLabel for="custom">Custom</OptionLabel>
        </SingleOption>

        <CustomAgent
          type="number"
          name="intialBalance"
          id="customAgentNum"
          placeholder="Enter you desired amount"
          customAgentNum={customAgentNum}
          disabled={customAgentNum ? false : true}
          onChange={handleCustomAgent}
          value={regFormInfo.intialBalance}
        />
      </OptionsCont>
    </MainContainer>
  );
};

export default DialerVoipScreenTwo;
