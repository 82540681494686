import React from "react";
import { Form } from "../components/index";





const HomePage = () => {
  return (
    <>
      <Form />
    </>
  );
};

export default HomePage;
