import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  MainContainer,
  Heading,
  SubHeading,
  OptionsCont,
  Option,
  SingleOption,
  OptionLabel,
  Title,
  Info,
  ToolText,
} from "./DialerScreenThree.elements";
import { CALCULATE_TOTALS, REGISTER_INVOICE } from "../../Endpoints";
import axios from "axios";

const DialerScreenThree = ({
  handleFormUpdate,
  regFormInfo,
  setOrderTotal,
}) => {
  const [selected, setSelected] = useState("");
  const [checked, setChecked] = useState(false);
  const [service, setService] = useState("");

  const handleCheck = (event) => {
    handleFormUpdate(event);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getTotals = await axios.post(CALCULATE_TOTALS, {
          service: regFormInfo.service,
          email: regFormInfo.email,
          serverType: regFormInfo.serverType,
          dedicatedSupport: regFormInfo.dedicatedSupport,
          noOfAgents: regFormInfo.noOfAgents,
          noOfDid: regFormInfo.noOfDid,
          intialBalance: regFormInfo.intialBalance,
          switchLocation: regFormInfo.switchLocation,
          switchCapacity: regFormInfo.switchCapacity,
          switchType: regFormInfo.switchType,
        });
        setOrderTotal(getTotals.data.totals);
        console.log("total updated");

        const registerInvoice = async () => {
          const data = regFormInfo;
          data.invoiceStatus = "pending";
          const response = await axios.post(REGISTER_INVOICE, data);
          console.log(response);
        };

        registerInvoice();
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <MainContainer>
      <Heading>Fill Up Service Details</Heading>
      {/* <SubHeading>Are you already a TalkAsia VoIP Service Provider?</SubHeading> */}

      <ReactTooltip
        place="top"
        type="dark"
        effect="solid"
        id="global"
        aria-haspopup="true"
        className="extraClass"
      >
        <ToolText>
          It included basic support for server related issues and
        </ToolText>
        <ToolText>
          issues that can cause laps in your service. Also support is
        </ToolText>
        <ToolText>given monday to friday, 9am to 7pm EST.</ToolText>
      </ReactTooltip>

      <OptionsCont>
        <Title>
          Need Dialer Support
          <Info data-tip data-for="global">
            ?
          </Info>
        </Title>
        <SingleOption
          style={{ background: service == "5" ? "#E5FFF1" : "" }}
          for="serverType"
        >
          <Option
            type="radio"
            name="dedicatedSupport"
            id="cloud"
            value="yes"
            onChange={handleCheck}
            checked={regFormInfo.dedicatedSupport === "yes" ? true : false}
          />
          <OptionLabel for="cloud">Yes 50$ / Month</OptionLabel>
        </SingleOption>
        <SingleOption
          style={{ background: service == "10" ? "#E5FFF1" : "" }}
          for="serverType"
        >
          <Option
            type="radio"
            name="dedicatedSupport"
            id="dedicated"
            value="no"
            onChange={handleCheck}
            checked={regFormInfo.dedicatedSupport === "no" ? true : false}
          />
          <OptionLabel for="dedicated">No</OptionLabel>
        </SingleOption>
      </OptionsCont>
    </MainContainer>
  );
};

export default DialerScreenThree;
