import styled from "styled-components";

export const MainContainer = styled.div`
  max-width: 1050px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 20px;
  @media (max-width: 470px) {
  }
`;

export const Heading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.24px;
  text-align: center;
  color: #000000;
  margin-bottom: 0px;
  margin-top: 0px;
`;

export const Green = styled.span`
  color: #36a369;
`;

export const Input = styled.input`
  max-width: 200px;
  width: 100%;
  height: 38px;
  margin: 0 auto;
  border: 1px solid #36a369;
  margin-bottom: 10px;
  margin-left: 20px;
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #989898;
  padding: 0px 19px;

  ::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #989898;
  }
`;

export const Content = styled.div`
  max-height: 400px;
  overflow-y: auto;
  margin: 20px 0;
`;
export const Text = styled.p`
  line-height: 1.5;
`;
export const SignatureWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 60%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SignatureContainer = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
`;

export const SignatureButtons = styled.div`
  display: flex;
  padding: 10px 20px;
  color: #fff;
  background-color: #36a369;
  margin-top: 20px;
  cursor: pointer;
`;

export const SignatureImg = styled.img`
  height: 50px;
  object-fit: contain;
  display: inline-block;
`;
