import styled from "styled-components";
import ReactFlagsSelect from "react-flags-select";

export const MainContainer = styled.div`
  max-width: 1050px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 38px;
  @media (max-width: 470px) {
  }
`;

export const SuccessImg = styled.img`
  max-width: 440px;
  margin-bottom: 38px;
`;

export const UserName = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  color: #36a369;
  margin: 0px;
`;

export const ThankYou = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #2b2b2b;
  margin: 0px;
`;

export const InfoText = styled.p`
  max-width: 463px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: #727272;

  box-sizing: border-box;

  @media (max-width: 470px) {
    padding: 0px 20px;
  }
`;

export const BankDetails = styled.div`
  border: 1px solid #36a369;
  padding: 22px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const BankInfo = styled.h4`
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin: 0px;
  text-align: center;
  color: #2b2b2b;
  text-align: left;
`;

export const BankInfoHeading = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  margin: 0px;
  text-align: center;
  color: #36a369;
  text-align: left;
  margin-right: 6px;
`;

export const Note = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  color: #2b2b2b;
  margin: 0px;
  margin-bottom: 26px;
`;

export const Invert = styled.span`
  color: #36a369;
`;

export const FinishButton = styled.a`
  max-width: 440px;
  width: 100%;
  height: 50px;
  background: #36a369;
  border-radius: 4px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  margin-top: 38px;
  cursor: pointer;
  &:hover {
    background: #2b8a59;
  }
`;

export const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupInner = styled.div`
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 500px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PopupClose = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
  padding: 16px;
  cursor: pointer;
`;

export const PopupTitle = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  color: #2b2b2b;
  margin: 0px;
  margin-bottom: 16px;
`;
