import styled from "styled-components";
import ReactFlagsSelect from "react-flags-select";

export const MainContainer = styled.div`
  max-width: 1050px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 470px) {
  }
`;

export const Heading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.24px;
  text-align: center;
  color: #000000;
  margin-bottom: 0px;
  margin-top: 0px;
`;

export const SubHeading = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #727272;
  margin: 0;
  padding: 0;
  margin-bottom: 38px;
`;

export const Title = styled.h4`
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.24px;
  color: #000000;
  margin-bottom: 10px;
`;

export const OptionsCont = styled.div`
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  flex-wrap: wrap;
  margin-bottom: 38px;

  @media (max-width: 470px) {
    width: 90%;
  }
`;

export const OptionLabel = styled.label`
  width: 263px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  /* padding-right: 20px; */
`;

export const LabelImage = styled.img``;

export const SingleOption = styled.div`
  width: 263px;
  height: 75px;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  border: 1px solid #36a369;
  margin-bottom: 19px;
  margin-right: 24px;
  box-sizing: border-box;

  :last-child {
    margin-right: 0px;
  }
`;

export const Option = styled.input.attrs({ type: "radio" })`
  width: 20px;
  height: 20px;
  appearance: none;
  -webkit-appearance: none;

  &:after {
    display: block;
    content: "";
    width: 17px;
    height: 17px;
    border: 1px solid #36a369;
    border-radius: 50%;
    position: relative;
    top: -8px;
    left: -4px;
  }

  &:checked::after {
    content: url("assets/images/check-bg.svg");
    width: 17px;
    height: 17px;
    border: none;
  }

  &:checked + ${OptionLabel} {
    font-weight: 600;
    color: #1e623e;
  }
`;

export const CustomAgent = styled.input.attrs({
  type: "number",
  id: "customAgentNum",
})`
  width: 100%;
  height: 50px;
  background: ${(props) => (props.customAgentNum ? "transparent" : "#D7D7D7")};
  display: flex;
  align-items: center;
  border: 1px solid #36a369;
  margin-bottom: 18px;
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: #989898;
  padding: 0px 19px;

  ::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    color: #989898;
  }
`;

export const Country = styled(ReactFlagsSelect)`
  width: 100%;
  height: 50px;
  border: 1px solid #36a369;
  margin-bottom: 18px;
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: #989898;
  padding: 0px 19px;
  outline: none;

  ::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    color: #989898;
  }

  button {
    border: none;
  }
`;

export const ChekCont = styled.div`
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 38px;
`;

export const SingleCheck = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 470px) {
    padding: 0px 20px;
  }
`;

export const CheckBox = styled.input.attrs({ type: "checkbox" })`
  width: 14px;
  height: 14px;
  margin-right: 10px;
`;

export const CheckLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #727272;

  @media (max-width: 470px) {
    font-size: 12px;
  }
`;

export const SubmitCont = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 38px;
`;

export const SubmitButton = styled.button`
  max-width: 550px;
  width: 100%;
  height: 60px;
  outline: none;
  background: #ff36a0;
  border: 1px solid #ff36a0;
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;

  :hover {
    background: #ed3496;
    border-color: #ed3496;
  }
`;

export const OrderItem = styled.div`
  height: 16px;
  margin-bottom: 4px;
  display: flex;
`;

export const OrderItemHeading = styled.h6`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.24px;
  color: #000000;
  margin: 0px;
  margin-right: 4px;
`;

export const OrderItemValue = styled.h6`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: -0.24px;
  color: #000000;
  margin: 0px;
  text-transform: capitalize;
`;

export const TermsCont = styled.div`
  max-width: 550px;
  width: 100%;
  height: 238px;
  background: #f8f8f8;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 21px 60px 8px 31px;
  box-sizing: border-box;
  margin-bottom: 20px;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f8f8f8;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 36px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const TermsHeading = styled.h5`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #454545;
  margin-top: 0px;
  margin-bottom: 10px;
`;

export const TermsDesc = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #727272;
`;

export const InfoCont = styled.div`
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
  display:flex;
`;

export const InfoIcon = styled.img` 
width: 22px;
margin-right:11px;
`;

export const InfoText = styled.p`
font-family: 'Poppins';
font-style: normal; 
font-weight: 500;
font-size: 14px;
color: #F53838;
`;

export const ReadTerms = styled.a`
color:#1E76C0;
cursor:pointer;
`;

// export const div = styled.div``;
