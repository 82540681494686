import React, { useState, useRef } from "react";
import {
  MainContainer,
  Heading,
  SubHeading,
  ExtraInfo,
  InputCont,
  IdCont,
  Title,
  FileUpload,
  ImgCont,
  UploadInfo,
  UploadImg,
  UploadName,
  FileUploadButton,
  UploadButton,
  UploadImage,
  Error,
  ChooseIdCont,
  IdSingleOpt,
  IdSelectLabel,
  IdSelectRadio,
  IdSelectImage,
  Loader,
  InstContainer,
  InstHeading,
  InstUl,
  InstLi,
} from "./VoipScreenSix.elements";

import axios from "axios";
import { REGISTER_ID_PROOF, REGISTER_ID_TYPE } from "../../Endpoints";

const VoipScreenSix = ({
  handleFormUpdate,
  regFormInfo,
  isIdDocumentUpdated,
  setIdCardFrontName,
  setIdCardBackName,
  setIdName,
}) => {
  const [checked, setChecked] = useState(false);
  const [service, setService] = useState("");
  const [selected, setSelected] = useState("");
  const [fileName, setFileName] = useState("No File Choosen");
  const [idFrontName, setIdFrontName] = useState("No File Choosen");
  const [idBackName, setIdBackName] = useState("No File Choosen");
  const [idType, setIdType] = useState();
  const [error, setError] = useState("");
  const [IdLoading, setIdLoading] = useState(false);
  const [IdFrontLoading, setIdFrontLoading] = useState(false);
  const [IdBackLoading, setIdBackLoading] = useState(false);
  const hiddenFileUpload = useRef(null);
  const hiddenIdFront = useRef(null);

  const handleCheck = (event) => {
    console.log(event.target.value);
    setService(event.target.value);
    setChecked(!checked);
  };

  const handleUploadButtonClick = () => {
    hiddenFileUpload.current.click();
  };

  const handleIdFrontButtonClick = () => {
    hiddenIdFront.current.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    console.log(file);
    setFileName(file.name);
    handleFormUpdate(event);
  };

  const registerIdDocument = async (event) => {
    setIdName(event.target.files[0].name);

    if (
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "application/pdf" ||
      event.target.files[0].type === "image/png"
    ) {
      setError("");
      setIdLoading(true);
      const data = new FormData();
      data.append("IdProof", event.target.files[0]);
      data.append("email", regFormInfo.email);
      data.append("idType", "passport");

      const response = await axios.post(REGISTER_ID_PROOF, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);
      if (response.data.code == "201") {
        isIdDocumentUpdated("yes");
        setIdLoading(false);
      }
    } else {
      setError("Please upload PDF or Image file");
    }
  };

  const registerIdCardFront = async (event) => {
    setIdCardFrontName(event.target.files[0].name);

    if (
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "application/pdf" ||
      event.target.files[0].type === "image/png"
    ) {
      setError("");
      setIdFrontLoading(true);
      const data = new FormData();
      data.append("IdCardFront", event.target.files[0]);
      data.append("email", regFormInfo.email);
      data.append("idType", "idCard");
      data.append("idCardSide", "front");

      const response = await axios.post(REGISTER_ID_PROOF, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);
      if (response.data.code === "201") {
        setIdFrontLoading(false);
      }
    } else {
      setError("Please upload PDF or Image file");
    }
  };

  const registerIdCardBack = async (event) => {
    setIdCardBackName(event.target.files[0].name);

    if (
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "application/pdf" ||
      event.target.files[0].type === "image/png"
    ) {
      setError("");
      setIdBackLoading(true);
      const data = new FormData();
      data.append("IdCardBack", event.target.files[0]);
      data.append("email", regFormInfo.email);
      data.append("idType", "idCard");
      data.append("idCardSide", "back");

      const response = await axios.post(REGISTER_ID_PROOF, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);
      if (response.data.code === "201") {
        isIdDocumentUpdated("yes");
        setIdBackLoading(false);
      }
    } else {
      setError("Please upload PDF or Image file");
    }
  };

  const registerIdType = async (event) => {
    setIdType(event.target.value);
    const updateData = await handleFormUpdate(event);

    const response = await axios.post(REGISTER_ID_TYPE, {
      email: regFormInfo.email,
      idType: event.target.value,
    });
    console.log(response);
    if (response.data.code == "201") {
    }
  };

  return (
    <MainContainer>
      <Heading>Begin Your ID Verfication</Heading>
      <SubHeading>
        You are required to provide a form of ID in order by subscribe to
        TalkAsia's Service.
      </SubHeading>

      <ChooseIdCont>
        <IdSingleOpt>
          <IdSelectRadio
            type="radio"
            name="idType"
            id="passport"
            value="passport"
            onClick={registerIdType}
          />
          <IdSelectLabel for="passport">
            <IdSelectImage src="assets/images/passport.svg" /> Passport
          </IdSelectLabel>
        </IdSingleOpt>

        <IdSingleOpt>
          <IdSelectRadio
            type="radio"
            name="idType"
            id="idCard"
            value="id card"
            onClick={registerIdType}
          />
          <IdSelectLabel for="idCard" border="#36A369">
            <IdSelectImage src="assets/images/id-card.svg" /> ID Card
          </IdSelectLabel>
        </IdSingleOpt>

        <IdSingleOpt>
          <IdSelectRadio
            type="radio"
            name="idType"
            id="license"
            value="license"
            onClick={registerIdType}
          />
          <IdSelectLabel for="license" border="#FF369C">
            <IdSelectImage src="assets/images/license.svg" /> License
          </IdSelectLabel>
        </IdSingleOpt>
      </ChooseIdCont>

      <InstContainer>
        <InstHeading>
          To avoid delays when verifying account, please make sure bellow.
        </InstHeading>
        <InstUl>
          <InstLi>Chosen credential must not be expired</InstLi>
          <InstLi>
            Document should be in good condition and clearly visible.
          </InstLi>
          <InstLi>Make sure that there is no light glare on the card.</InstLi>
        </InstUl>
      </InstContainer>

      {idType === "passport" || idType === "license" ? (
        <>
          <Title>Upload Here Your Document</Title>

          <InputCont>
            <FileUpload>
              <UploadInfo>
                <UploadImg src="assets/images/file-upload-icon.svg" />
                <UploadName>
                  {" "}
                  {regFormInfo.setIdName == ""
                    ? "No File Choosen"
                    : regFormInfo.setIdName}
                </UploadName>
              </UploadInfo>

              <UploadButton onClick={handleUploadButtonClick}>
                {IdLoading ? (
                  <Loader src="assets/images/loader.png" />
                ) : (
                  "Choose File"
                )}
              </UploadButton>
              <Error> {error}</Error>
              <FileUploadButton
                type="file"
                name="IdProof"
                ref={hiddenFileUpload}
                onChange={registerIdDocument}
              />
            </FileUpload>

            <ImgCont>
              <UploadImage src="assets/images/file-upload-image.svg" />
            </ImgCont>
          </InputCont>
        </>
      ) : (
        ""
      )}
      {idType == "id card" ? (
        <IdCont>
          <FileUpload>
            <UploadInfo>
              <UploadImg src="assets/images/file-upload-icon.svg" />
              <UploadName>
                {regFormInfo.IdCardFrontName == ""
                  ? "No File Choosen"
                  : regFormInfo.IdCardFrontName}
              </UploadName>
            </UploadInfo>

            <UploadButton onClick={handleIdFrontButtonClick}>
              {IdFrontLoading ? (
                <Loader src="assets/images/loader.png" />
              ) : (
                "Choose ID Card Front"
              )}
            </UploadButton>
            <Error> {error}</Error>
            <FileUploadButton
              type="file"
              name="IdCardFront"
              ref={hiddenIdFront}
              onChange={registerIdCardFront}
            />
          </FileUpload>

          <FileUpload>
            <UploadInfo>
              <UploadImg src="assets/images/file-upload-icon.svg" />
              <UploadName>
                {regFormInfo.setIdCardBackName == ""
                  ? "No File Choosen"
                  : regFormInfo.setIdCardBackName}
              </UploadName>
            </UploadInfo>

            <UploadButton onClick={handleUploadButtonClick}>
              {IdBackLoading ? (
                <Loader src="assets/images/loader.png" />
              ) : (
                "Choose ID Card Back"
              )}
            </UploadButton>
            <Error> {error}</Error>
            <FileUploadButton
              type="file"
              name="IdCardBack"
              ref={hiddenFileUpload}
              onChange={registerIdCardBack}
            />
          </FileUpload>
        </IdCont>
      ) : (
        ""
      )}
    </MainContainer>
  );
};

export default VoipScreenSix;
