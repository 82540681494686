import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Services from "./Services/Services";
import Registration from "./Registration/Registration";
import Payment from "../Payment/Payment";

import {
  MainContainer,
  LeftCont,
  Image,
  RightCont,
  Slider,
  Padding,
} from "./Form.elements";
import DialerVoipScreenOne from "./DialerVoipScreenOne/DialerVoipScreenOne";
import DialerVoipScreenTwo from "./DialerVoipScreenTwo/DialerVoipScreenTwo";
import OrderSummary from "../OrderSummary/OrderSummary";
import Success from "../Success/Success";
import DialerVoipScreenFour from "./DialerVoipScreenFour/DialerVoipScreenFour";
import DialerVoipScreenThree from "./DialerVoipScreenThree/DialerVoipScreenThree";
import { useNavigate } from "react-router-dom";
import DialerVoipScreenNew from "./DialerVoipScreenNew/DialerVoipScreenNew";

const From = () => {
  const [selectedService, setSelectedService] = useState("dialervoip");
  const [currentStep, setCurrentStep] = useState("services");
  const [sliderStep, setSliderStep] = useState(1);
  const [maxSliderStep, setMaxSliderStep] = useState(7);
  const [switchPrices, setSwitchPrices] = useState("");
  const [regFormInfo, setRegFormInfo] = useState({
    service: "dialervoip",
    firstName: "",
    lastName: "",
    companyName: "",
    address: "",
    city: "",
    state: "",
    selectedCountry: "",
    email: "",
    isEmailVerified: "",
    phone: "",
    isPhoneVerified: "",
    skype: "",
    isCompanyCertificateUpdated: "",
    isIdDocumentUpdated: "",
    TermsConditionsAccepted: "",
    personalInformationConsent: "",
    paymentMethod: "",
    noOfAgents: "",
    agentCountry: "",
    serverType: "",
    campaign: "",
    kycRmd: "",
    kycSignature: "",
    kycDate: "",
    kycName: "",
    dedicatedSupport: "",
    serviceType: "",
    monthlyTermination: "",
    monthlyOrigination: "",
    sourceOfNumbers: "",
    FCCRegistrationNumber: "",
    filerId: "",
    legalEntityFiles: "",
    mitigationDatabaseName: "",
    userName: "",
    serverIp: "",
    switchType: "",
    intialBalance: "",
    noOfDid: "",
    newToAmazon: "",
    switchHosted: "",
    oneTimeInstallation: "",
    fileName: "",
    IdCardFrontName: "",
    setIdCardBackName: "",
    setIdName: "",
  });
  const [cartItem, setCartItem] = useState({
    cartTotal: 0,
  });

  const handleChange = (event) => {
    setSelectedService(event.target.value);
    // setCurrentStep("services");
  };

  const navigate = useNavigate();

  const handleBackStep = () => {
    setSliderStep((prevStep) => prevStep - 1);
    switch (currentStep) {
      case "services":
        navigate("/");
        break;
      case "registration":
        setCurrentStep("services");
        break;
      case "dialerDetails":
        setCurrentStep("registration");
        break;
      case "voipDetails":
        setCurrentStep("dialerDetails");
        break;
      case "noOfDid":
        setCurrentStep("voipDetails");
        break;
      case "companyCertificate":
        setCurrentStep("noOfDid");
        break;
      case "orderSummary":
        setCurrentStep("companyCertificate");
        break;
      default:
        setCurrentStep(currentStep);
    }
  };

  const handleStep = () => {
    // setCurrentStep((prevStep) => prevStep + 1);
    setSliderStep((prevStep) => prevStep + 1);
    setMaxSliderStep(6);
    switch (currentStep) {
      case "services":
        setCurrentStep("registration");
        break;
      case "registration":
        setCurrentStep("dialerDetails");
        break;
      case "dialerDetails":
        setCurrentStep("voipDetails");
        break;
      case "voipDetails":
        setCurrentStep("noOfDid");
        break;
      case "noOfDid":
        setCurrentStep("companyCertificate");
        break;
      case "companyCertificate":
        setCurrentStep("orderSummary");
        break;
      case "orderSummary":
        setCurrentStep("paymentMethod");
        break;
      case "paymentMethod":
        setCurrentStep("successPage");
        break;

      default:
        setCurrentStep("services");
    }
  };

  const handleFormUpdate = (event) => {
    let value = event.target.value;
    // console.log("name on back", name);
    setRegFormInfo((prevState) => ({
      ...prevState,
      [event.target.name]: value,
    }));
  };

  const handleSignature = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      kycSignature: value,
    }));
  };

  const handleFormEmailUpdate = (isVerified) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      isEmailVerified: isVerified,
    }));
  };

  const handleFormPhoneUpdate = (isVerified) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      isPhoneVerified: isVerified,
    }));
  };

  const isIdDocumentUpdated = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      isIdDocumentUpdated: value,
    }));
  };

  const setPhoneNumber = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      phone: value,
    }));
  };

  const setPhoneCountryCode = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      phoneCountryCode: value,
    }));
  };

  const setPhoneWithoutCountry = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      phoneWithoutCountry: value,
    }));
  };

  const setOrderTotal = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      total: value,
    }));
  };

  const setPaymentMethod = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      paymentMethod: value,
    }));
  };

  const setTermsConditions = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      TermsConditionsAccepted: value,
    }));
  };

  const setPersonalInfo = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      personalInformationConsent: value,
    }));
  };

  const setIdCardFrontName = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      IdCardFrontName: value,
    }));
  };

  const setIdCardBackName = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      setIdCardBackName: value,
    }));
  };

  const setIdName = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      setIdName: value,
    }));
  };

  const setCustomAgent = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      setCustomAgent: value,
    }));
  };

  return (
    <MainContainer>
      <LeftCont>
        <Image src="assets/images/bg.svg" />
      </LeftCont>
      <RightCont>
        <Header cartItem={cartItem} />

        <Padding>
          <Slider type="range" min="0" max={maxSliderStep} value={sliderStep} />
        </Padding>

        {/* {(() => {
          switch (selectedService) {
            case "dialer":
              return <Registration />;
            case "voip":
              return <div>You are a on voip.</div>;
            case "dialervoip":
              return <div>You are a on dialervoip.</div>;
            case "switch":
              return <div>You are a on switch.</div>;
            case "amazon":
              return <div>You are a on amazon.</div>;
            default:
              return <Services handleChange={handleChange} />;
          }
        })()} */}

        {(() => {
          switch (currentStep) {
            case "services":
              return (
                <Registration
                  handleFormUpdate={handleFormUpdate}
                  handleFormEmailUpdate={handleFormEmailUpdate}
                  handleFormPhoneUpdate={handleFormPhoneUpdate}
                  setPhoneNumber={setPhoneNumber}
                  setPhoneCountryCode={setPhoneCountryCode}
                  setPhoneWithoutCountry={setPhoneWithoutCountry}
                  regFormInfo={regFormInfo}
                />
              );
            case "registration":
              return (
                <DialerVoipScreenOne
                  handleFormUpdate={handleFormUpdate}
                  regFormInfo={regFormInfo}
                  setCustomAgent={setCustomAgent}
                />
              );
            case "dialerDetails":
              return (
                <DialerVoipScreenTwo
                  handleFormUpdate={handleFormUpdate}
                  regFormInfo={regFormInfo}
                />
              );
            case "voipDetails":
              return (
                <DialerVoipScreenNew
                  handleChange={handleChange}
                  handleFormUpdate={handleFormUpdate}
                  regFormInfo={regFormInfo}
                  handleSignatureInput={handleSignature}
                />
              );

            case "noOfDid":
              return (
                <DialerVoipScreenFour
                  handleFormUpdate={handleFormUpdate}
                  regFormInfo={regFormInfo}
                  isIdDocumentUpdated={isIdDocumentUpdated}
                  setIdCardFrontName={setIdCardFrontName}
                  setIdCardBackName={setIdCardBackName}
                  setIdName={setIdName}
                  handleStep={handleStep}
                  setOrderTotal={setOrderTotal}
                />
              );
            case "companyCertificate":
              return (
                <OrderSummary
                  handleFormUpdate={handleFormUpdate}
                  regFormInfo={regFormInfo}
                  setOrderTotal={setOrderTotal}
                  setTermsConditions={setTermsConditions}
                  setPersonalInfo={setPersonalInfo}
                />
              );
            case "orderSummary":
              return (
                <Success
                  regFormInfo={regFormInfo}
                  setPaymentMethod={setPaymentMethod}
                  handleStep={handleStep}
                />
              );

            default:
              return (
                <Services
                  handleChange={handleChange}
                  handleFormUpdate={handleFormUpdate}
                  regFormInfo={regFormInfo}
                />
              );
          }
        })()}

        <Footer
          handleStep={handleStep}
          handleBackStep={handleBackStep}
          currentStep={currentStep}
          selectedService={selectedService}
          regFormInfo={regFormInfo}
          CartItemInfo={cartItem}
        />
      </RightCont>
    </MainContainer>
  );
};

export default From;
