import styled from "styled-components";
import ReactFlagsSelect from "react-flags-select";

export const MainContainer = styled.div`
  max-width: 1050px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 470px) {
  }
`;

export const Heading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.24px;
  text-align: center;
  color: #000000;
  margin-bottom: 0px;
  margin-top: 0px;
`;

export const SubHeading = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #36a369;
  margin-bottom: 0px;
  margin-top: 0px;
`;

export const ExtraInfo = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #727272;
  text-align: center;
  margin-bottom: 38px;
  margin-top: 0px;
`;

export const InputCont = styled.div`
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 38px;

  @media (max-width: 470px) {
    width: 90%;
    justify-content: center;
  }
`;

export const Title = styled.h4`
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: -0.24px;
  color: #000000;
  margin-bottom: 20px;

  @media only screen and (max-width: 700px) {
    text-align: center;
    font: 14px;
  }
`;

export const FileUpload = styled.div`
  width: 210px;
`;

export const ImgCont = styled.div`
  width: 210px;

  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

export const UploadInfo = styled.div`
  width: 210px;
  height: 187px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #e5fff1;
  border: 1px dashed #36a369;
  box-sizing: border-box;
  border-radius: 0px;
  margin-bottom: 10px;
  overflow: hidden;
`;

export const UploadImg = styled.img`
  width: 50px;
  margin-bottom: 12.25px;
`;

export const UploadName = styled.h6`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #1e623e;
  margin: 0px;
`;

export const FileUploadButton = styled.input`
  display: none;
`;

export const UploadButton = styled.button`
  width: 210px;
  height: 38px;
  background: #36a369;
  border: 1px solid #36a369;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
`;

export const UploadImage = styled.img`
  width: 230px;
`;

export const Error = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ff3333;
  text-align: center;
`;

// export const div = styled.div``;
