import React, { useState } from "react";
import {
  MainContainer,
  Heading,
  SubHeading,
  InputCont,
  Input,
  Title,
} from "./VoipScreenSeven.elements";

const VoipScreenSeven = ({ handleFormUpdate, regFormInfo }) => {
  const [checked, setChecked] = useState(false);
  const [service, setService] = useState("");
  const [selected, setSelected] = useState("");
  const [verified, setVerified] = useState(true);

  const handleCheck = (event) => {
    console.log(event.target.value);
    setService(event.target.value);
    setChecked(!checked);
  };

  const updateRegInfo = (event) => {
    handleFormUpdate(event);
    console.log("country:", selected);
    // console.log("name:", propName);
    // console.log("value:", value);
  };

  const updateCountryInfo = (event) => {
    handleFormUpdate(event);
    console.log("country:", selected);
    // console.log("name:", propName);
    // console.log("value:", value);
  };

  return (
    <MainContainer>
      <Heading>Fill Up Service Details</Heading>
      {/* <SubHeading>Are you already a TalkAsia VoIP Service Provider?</SubHeading> */}

      <InputCont>
        <Title>Choose Username</Title>
        <Input
          type="text"
          name="userName"
          placeholder="Username You Want"
          onChange={updateRegInfo}
          value={regFormInfo.userName}
        />
        <Input
          type="text"
          name="serverIp"
          placeholder="Your Server IP"
          onChange={updateRegInfo}
          value={regFormInfo.serverIp}
        />

        <Input
          type="text"
          name="switchType"
          placeholder="Your Switch Type"
          onChange={updateRegInfo}
          value={regFormInfo.switchType}
        />
      </InputCont>
    </MainContainer>
  );
};

export default VoipScreenSeven;
