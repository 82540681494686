import React, { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  MainContainer,
  Heading,
  SubHeading,
  OptionsCont,
  Option,
  SingleOption,
  OptionLabel,
  Title,
  Info,
  ToolText,
} from "./SwitchScreenOne.elements";

const SwitchScreenOne = ({ handleFormUpdate, regFormInfo }) => {
  const [selected, setSelected] = useState("");
  const [checked, setChecked] = useState(false);
  const [service, setService] = useState("");

  const handleCheck = (event) => {
    setService(event.target.value);
    handleFormUpdate(event);
  };

  return (
    <MainContainer>
      <Heading>Fill Up Service Details</Heading>
      {/* <SubHeading>Are you already a TalkAsia VoIP Service Provider?</SubHeading> */}

      <OptionsCont>
        <Title>What services do you need?</Title>
        <SingleOption
          style={{
            background: service == "yes" ? "#E5FFF1" : "",
          }}
          for="newAmazon"
        >
          <Option
            type="radio"
            name="switchType"
            id="switchHosted"
            value="hostedSwitch"
            onChange={handleCheck}
            checked={regFormInfo.switchType === "hostedSwitch" ? true : false}
          />
          <OptionLabel for="switchHosted">Hosted switch</OptionLabel>
        </SingleOption>
        <SingleOption
          style={{ background: service == "no" ? "#E5FFF1" : "" }}
          for="old"
        >
          <ReactTooltip
            place="top"
            type="dark"
            effect="solid"
            id="global"
            aria-haspopup="true"
            className="extraClass"
          >
            <ToolText>
              We will install Switch on your server. Minimum requirements are
            </ToolText>
            <ToolText>
              Xeon E5 CPU, 32gb Ram, 2TB Hard disk (No Raid) and 2 IPs (1 server
              ip and 1 extra ip).
            </ToolText>
            <ToolText>After installation support is not included.</ToolText>
          </ReactTooltip>
          <Option
            type="radio"
            name="switchType"
            id="oneTimeInstallation"
            value="oneTimeInstallation"
            onChange={handleCheck}
            checked={
              regFormInfo.switchType === "oneTimeInstallation" ? true : false
            }
          />
          <OptionLabel for="oneTimeInstallation">
            One time installation $500 / Server{" "}
            <Info data-tip data-for="global">
              ?
            </Info>
          </OptionLabel>
        </SingleOption>
      </OptionsCont>
    </MainContainer>
  );
};

export default SwitchScreenOne;
