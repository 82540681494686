import styled from "styled-components";
import ReactFlagsSelect from "react-flags-select";

export const MainContainer = styled.div`
  max-width: 1050px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 470px) {
  }
`;

export const Heading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.24px;
  text-align: center;
  color: #000000;
  margin-bottom: 0px;
  margin-top: 0px;
`;

export const SubHeading = styled.h4`
  max-width: 555px;
  width: 100%;
  margin: 0 auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #727272;
  text-align: center;

  margin-top: 0px;
  margin-bottom: 60px;
`;

export const ExtraInfo = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #727272;
  text-align: center;
  margin-bottom: 38px;
  margin-top: 0px;
`;

export const InputCont = styled.div`
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 38px;

  @media (max-width: 470px) {
    width: 90%;
    justify-content: center;
  }
`;

export const IdCont = styled.div`
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 38px;

  @media (max-width: 470px) {
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.h4`
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: -0.24px;
  color: #000000;
  margin-bottom: 20px;

  @media only screen and (max-width: 700px) {
    text-align: center;
    font-size: 14px;
  }
`;

export const FileUpload = styled.div`
  width: 210px;

  @media only screen and (max-width: 700px) {
    margin-bottom: 20px;
  }
`;

export const ImgCont = styled.div`
  width: 210px;

  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

export const UploadInfo = styled.div`
  width: 210px;
  height: 187px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #e5fff1;
  border: 1px dashed #36a369;
  box-sizing: border-box;
  border-radius: 0px;
  margin-bottom: 10px;
  overflow: hidden;
`;

export const UploadImg = styled.img`
  width: 50px;
  margin-bottom: 12.25px;
`;

export const UploadName = styled.h6`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #1e623e;
  margin: 0px;
`;

export const FileUploadButton = styled.input`
  display: none;
`;

export const UploadButton = styled.button`
  width: 210px;
  height: 38px;
  background: #36a369;
  border: 1px solid #36a369;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
`;

export const UploadImage = styled.img`
  width: 230px;
`;
export const Error = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ff3333;
  text-align: center;
`;

export const ChooseIdCont = styled.div`
  max-width: 658px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const IdSingleOpt = styled.div`
  @media only screen and (max-width: 700px) {
    margin-bottom: 20px;
  }
`;

export const IdSelectLabel = styled.label`
  width: 200px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => (props.border ? props.border : "#125d9d")};
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: ${(props) => (props.border ? props.border : "#125d9d")};
  cursor: pointer;
`;

export const IdSelectRadio = styled.input.attrs({ type: "radio" })`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${IdSelectLabel} {
    border: 4px solid #36a369;
  }
`;

export const IdSelectImage = styled.img`
  width:32px;
  margin-right: 16px; 
`;

export const Loader = styled.img`
  margin-right: 7px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const InstContainer = styled.div`
max-width:657px;
width:100%;
margin: 0 auto;
`;

export const InstHeading = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
align-items: center;
color: #36A369; 

@media (max-width: 470px) {
    padding:0px 20px;
  }
`;

export const InstUl = styled.ul`
list-style-type:disc;
`;

export const InstLi = styled.li`
list-style-type:disc;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 26px;
color: #727272;
`;
 

// export const div = styled.div``;
// export const div = styled.div``;
