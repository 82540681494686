import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Services from "./Services/Services";
import Registration from "./Registration/Registration";
import SwitchScreenOne from "./SwitchScreenOne/SwitchScreenOne";
import SwitchScreenTwo from "./SwitchScreenTwo/SwitchScreenTwo";
import Payment from "../Payment/Payment";

import {
  MainContainer,
  LeftCont,
  Image,
  RightCont,
  Slider,
  Padding,
} from "./Form.elements";
import OrderSummary from "../OrderSummary/OrderSummary";
import Success from "../Success/Success";
import { useNavigate } from "react-router-dom";

const From = () => {
  const [selectedService, setSelectedService] = useState("switch");
  const [currentStep, setCurrentStep] = useState("services");
  const [sliderStep, setSliderStep] = useState(1);
  const [maxSliderStep, setMaxSliderStep] = useState(5);
  const [switchPrices, setSwitchPrices] = useState("");
  const [regFormInfo, setRegFormInfo] = useState({
    service: "switch",
    firstName: "",
    lastName: "",
    companyName: "",
    address: "",
    city: "",
    state: "",
    selectedCountry: "",
    email: "",
    isEmailVerified: "",
    phone: "",
    isPhoneVerified: "",
    skype: "",
    isCompanyCertificateUpdated: "",
    isIdDocumentUpdated: "",
    TermsConditionsAccepted: "",
    personalInformationConsent: "",
    paymentMethod: "",
    noOfAgents: "",
    agentCountry: "",
    serverType: "",
    dedicatedSupport: "",
    dedicatedSupport: "",
    serviceType: "",
    monthlyTermination: "",
    monthlyOrigination: "",
    sourceOfNumbers: "",
    FCCRegistrationNumber: "",
    filerId: "",
    legalEntityFiles: "",
    mitigationDatabaseName: "",
    userName: "",
    serverIp: "",
    switchType: "",
    intialBalance: "",
    noOfDid: "",
    newToAmazon: "",
    switchHosted: "",
    oneTimeInstallation: "",
    fileName: "",
    IdCardFrontName: "",
    setIdCardBackName: "",
    setIdName: "",
  });
  const [cartItem, setCartItem] = useState({
    cartTotal: 0,
  });

  const handleChange = (event) => {
    setSelectedService(event.target.value);
    // setCurrentStep("services");
  };

  const navigate = useNavigate();

  const handleBackStep = () => {
    setSliderStep((prevStep) => prevStep - 1);
    switch (currentStep) {
      case "services":
        navigate("/");
        break;
      case "registration":
        setCurrentStep("services");
        break;
      case "switchType":
        setCurrentStep("registration");
        break;
      case "balanceAndCapacity":
        setCurrentStep("switchType");
        break;

      default:
        setCurrentStep(currentStep);
    }
  };

  const handleStep = () => {
    // setCurrentStep((prevStep) => prevStep + 1);
    setSliderStep((prevStep) => prevStep + 1);
    setMaxSliderStep(5);
    switch (currentStep) {
      case "services":
        setCurrentStep("registration");
        break;
      case "registration":
        setCurrentStep("switchType");
        break;
      case "switchType":
        if (regFormInfo.switchType === "hostedSwitch") {
          setCurrentStep("balanceAndCapacity");
        } else {
          setMaxSliderStep(4);
          setCurrentStep("orderSummary");
        }
        break;
      case "orderSummary":
        setCurrentStep("paymentMethod");
        break;
      case "balanceAndCapacity":
        setCurrentStep("orderSummary");
        break;
      case "paymentMethod":
        setCurrentStep("successPage");
        break;

      default:
        setCurrentStep("services");
    }

    console.log(currentStep);
  };

  const handleFormUpdate = (event) => {
    // console.log(event);
    let finalName = event.target.name;
    let value = event.target.value;
    // console.log("name on back", name);
    setRegFormInfo((prevState) => ({
      ...prevState,
      [event.target.name]: value,
    }));
  };

  const handleFormEmailUpdate = (isVerified) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      isEmailVerified: isVerified,
    }));
  };

  const handleFormPhoneUpdate = (isVerified) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      isPhoneVerified: isVerified,
    }));
  };

  const setPhoneNumber = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      phone: value,
    }));
  };

  const setPhoneCountryCode = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      phoneCountryCode: value,
    }));
  };

  const setPhoneWithoutCountry = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      phoneWithoutCountry: value,
    }));
  };

  const setOrderTotal = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      total: value,
    }));
  };

  const setPaymentMethod = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      paymentMethod: value,
    }));
  };

  const setTermsConditions = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      TermsConditionsAccepted: value,
    }));
  };

  const setPersonalInfo = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      personalInformationConsent: value,
    }));
  };

  return (
    <MainContainer>
      <LeftCont>
        <Image src="assets/images/bg.svg" />
      </LeftCont>
      <RightCont>
        <Header cartItem={cartItem} />

        <Padding>
          <Slider type="range" min="0" max={maxSliderStep} value={sliderStep} />
        </Padding>

        {(() => {
          switch (currentStep) {
            case "services":
              return (
                <Registration
                  handleFormUpdate={handleFormUpdate}
                  handleFormEmailUpdate={handleFormEmailUpdate}
                  handleFormPhoneUpdate={handleFormPhoneUpdate}
                  setPhoneNumber={setPhoneNumber}
                  setPhoneCountryCode={setPhoneCountryCode}
                  setPhoneWithoutCountry={setPhoneWithoutCountry}
                  regFormInfo={regFormInfo}
                />
              );
            case "registration":
              return (
                <SwitchScreenOne
                  handleFormUpdate={handleFormUpdate}
                  regFormInfo={regFormInfo}
                />
              );
            case "switchType":
              if (regFormInfo.switchType == "hostedSwitch") {
                return (
                  <SwitchScreenTwo
                    handleFormUpdate={handleFormUpdate}
                    regFormInfo={regFormInfo}
                    setSwitchPrices={setSwitchPrices}
                    switchPrices={switchPrices}
                  />
                );
              } else {
                return (
                  <OrderSummary
                    handleFormUpdate={handleFormUpdate}
                    regFormInfo={regFormInfo}
                    setOrderTotal={setOrderTotal}
                    setTermsConditions={setTermsConditions}
                    setPersonalInfo={setPersonalInfo}
                  />
                );
              }

            case "orderSummary":
              return (
                <Success
                  regFormInfo={regFormInfo}
                  setPaymentMethod={setPaymentMethod}
                  handleStep={handleStep}
                />
              );
            case "balanceAndCapacity":
              return (
                <OrderSummary
                  handleFormUpdate={handleFormUpdate}
                  regFormInfo={regFormInfo}
                  setOrderTotal={setOrderTotal}
                  setTermsConditions={setTermsConditions}
                  setPersonalInfo={setPersonalInfo}
                />
              );

            default:
              return (
                <Services
                  handleChange={handleChange}
                  handleFormUpdate={handleFormUpdate}
                  regFormInfo={regFormInfo}
                />
              );
          }
        })()}

        <Footer
          handleStep={handleStep}
          handleBackStep={handleBackStep}
          currentStep={currentStep}
          selectedService={selectedService}
          regFormInfo={regFormInfo}
          CartItemInfo={cartItem}
        />
      </RightCont>
    </MainContainer>
  );
};

export default From;
