import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Services from "./Services/Services";
import Registration from "./Registration/Registration";
import VoipScreenOne from "./VoipScreenOne/VoipScreenOne";

import {
  MainContainer,
  LeftCont,
  Image,
  RightCont,
  Slider,
  Padding,
} from "./Form.elements";
import VoipScreenTwo from "./VoipScreenTwo/VoipScreenTwo";
import VoipScreenThree from "./VoipScreenThree/VoipScreenThree";
import VoipScreenFour from "./VoipScreenFour/VoipScreenFour";
import VoipScreenSeven from "./VoipScreenSeven/VoipScreenSeven";
import VoipScreenFive from "./VoipScreenFive/VoipScreenFive";
import VoipScreenSix from "./VoipScreenSix/VoipScreenSix";
import OrderSummary from "../OrderSummary/OrderSummary";
import Success from "../Success/Success";
import { useNavigate } from "react-router-dom";
const From = () => {
  const [selectedService, setSelectedService] = useState("voip");
  const [currentStep, setCurrentStep] = useState("services");
  const [sliderStep, setSliderStep] = useState(1);
  const [maxSliderStep, setMaxSliderStep] = useState(10);
  const [switchPrices, setSwitchPrices] = useState("");
  const [regFormInfo, setRegFormInfo] = useState({
    service: "voip",
    firstName: "",
    lastName: "",
    companyName: "",
    address: "",
    city: "",
    state: "",
    selectedCountry: "",
    email: "",
    isEmailVerified: "",
    phone: "",
    isPhoneVerified: "",
    skype: "",
    isCompanyCertificateUpdated: "",
    isIdDocumentUpdated: "",
    TermsConditionsAccepted: "",
    personalInformationConsent: "",
    paymentMethod: "",
    noOfAgents: "",
    agentCountry: "",
    serverType: "",
    dedicatedSupport: "",
    dedicatedSupport: "",
    serviceType: "",
    monthlyTermination: "",
    monthlyOrigination: "",
    sourceOfNumbers: "",
    FCCRegistrationNumber: "",
    filerId: "",
    legalEntityFiles: "",
    mitigationDatabaseName: "",
    userName: "",
    serverIp: "",
    switchType: "",
    intialBalance: "",
    noOfDid: "",
    newToAmazon: "",
    switchHosted: "",
    oneTimeInstallation: "",
    fileName: "",
    IdCardFrontName: "",
    setIdCardBackName: "",
    setIdName: "",
  });
  const [cartItem, setCartItem] = useState({
    cartTotal: 0,
  });

  const handleChange = (event) => {
    setSelectedService(event.target.value);
    // setCurrentStep("services");
  };

  const navigate = useNavigate();

  const handleBackStep = () => {
    setSliderStep((prevStep) => prevStep - 1);
    switch (currentStep) {
      case "services":
        navigate("/");
        break;
      case "registration":
        setCurrentStep("services");
        break;
      case "serviceType":
        setCurrentStep("registration");
        break;
      case "sourceOfNumbers":
        setCurrentStep("serviceVolume");
        break;
      case "companyInformation":
        setCurrentStep("sourceOfNumbers");
        break;
      case "serviceVolume":
        setCurrentStep("serviceType");
        break;
      case "companyCertificate":
        setCurrentStep("companyInformation");
        break;
      case "profOfAddress":
        setCurrentStep("companyCertificate");
        break;
      case "OrderSummary":
        setCurrentStep("profOfAddress");
        break;
      default:
        setCurrentStep(currentStep);
    }
  };

  const handleStep = () => {
    // setCurrentStep((prevStep) => prevStep + 1);
    setSliderStep((prevStep) => prevStep + 1);
    setMaxSliderStep(10);
    switch (currentStep) {
      case "services":
        setCurrentStep("registration");
        break;
      case "registration":
        setCurrentStep("serviceType");
        break;
      case "serviceType":
        setCurrentStep("serviceVolume");
        break;
      case "serviceVolume":
        setCurrentStep("sourceOfNumbers");
        break;
      case "sourceOfNumbers":
        setCurrentStep("companyInformation");
        break;
      case "companyInformation":
        setCurrentStep("companyCertificate");
        break;
      case "companyCertificate":
        setCurrentStep("profOfAddress");
        break;
      case "profOfAddress":
        setCurrentStep("OrderSummary");
        break;
      case "OrderSummary":
        setCurrentStep("successPage");
        break;
      default:
        setCurrentStep("services");
    }
  };

  const handleFormUpdate = (event) => {
    // console.log(event);
    let finalName = event.target.name;
    let value = event.target.value;
    // console.log("name on back", name);
    setRegFormInfo((prevState) => ({
      ...prevState,
      [event.target.name]: value,
    }));
  };

  const handleFormEmailUpdate = (isVerified) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      isEmailVerified: isVerified,
    }));
  };

  const handleFormPhoneUpdate = (isVerified) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      isPhoneVerified: isVerified,
    }));
  };

  const isCompanyCertificateUpdated = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      isCompanyCertificateUpdated: value,
    }));
  };

  const isIdDocumentUpdated = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      isIdDocumentUpdated: value,
    }));
  };

  const setPhoneNumber = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      phone: value,
    }));
  };

  const setPhoneCountryCode = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      phoneCountryCode: value,
    }));
  };

  const setPhoneWithoutCountry = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      phoneWithoutCountry: value,
    }));
  };

  const setOrderTotal = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      total: value,
    }));
  };

  const setTermsConditions = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      TermsConditionsAccepted: value,
    }));
  };

  const setPersonalInfo = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      personalInformationConsent: value,
    }));
  };

  const setCompanyCertificateeName = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      fileName: value,
    }));
  };

  const setIdCardFrontName = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      IdCardFrontName: value,
    }));
  };

  const setIdCardBackName = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      setIdCardBackName: value,
    }));
  };

  const setIdName = (value) => {
    setRegFormInfo((prevState) => ({
      ...prevState,
      setIdName: value,
    }));
  };

  return (
    <MainContainer>
      <LeftCont>
        <Image src="assets/images/bg.svg" />
      </LeftCont>
      <RightCont>
        <Header cartItem={cartItem} />

        <Padding>
          <Slider type="range" min="0" max={maxSliderStep} value={sliderStep} />
        </Padding>

        {/* {(() => {
          switch (selectedService) {
            case "dialer":
              return <Registration />;
            case "voip":
              return <div>You are a on voip.</div>;
            case "dialervoip":
              return <div>You are a on dialervoip.</div>;
            case "switch":
              return <div>You are a on switch.</div>;
            case "amazon":
              return <div>You are a on amazon.</div>;
            default:
              return <Services handleChange={handleChange} />;
          }
        })()} */}

        {(() => {
          switch (currentStep) {
            case "services":
              return (
                <Registration
                  handleFormUpdate={handleFormUpdate}
                  handleFormEmailUpdate={handleFormEmailUpdate}
                  handleFormPhoneUpdate={handleFormPhoneUpdate}
                  setPhoneNumber={setPhoneNumber}
                  setPhoneCountryCode={setPhoneCountryCode}
                  setPhoneWithoutCountry={setPhoneWithoutCountry}
                  regFormInfo={regFormInfo}
                />
              );
            case "registration":
              return (
                <VoipScreenOne
                  handleFormUpdate={handleFormUpdate}
                  regFormInfo={regFormInfo}
                />
              );
            case "serviceType":
              return (
                <VoipScreenTwo
                  handleFormUpdate={handleFormUpdate}
                  regFormInfo={regFormInfo}
                />
              );
            case "serviceVolume":
              return (
                <VoipScreenThree
                  handleFormUpdate={handleFormUpdate}
                  regFormInfo={regFormInfo}
                />
              );
            case "sourceOfNumbers":
              return (
                <VoipScreenFour
                  handleFormUpdate={handleFormUpdate}
                  regFormInfo={regFormInfo}
                />
              );
            case "companyInformation":
              return (
                <VoipScreenFive
                  handleFormUpdate={handleFormUpdate}
                  regFormInfo={regFormInfo}
                  isCompanyCertificateUpdated={isCompanyCertificateUpdated}
                  setCompanyCertificateeName={setCompanyCertificateeName}
                />
              );
            case "companyCertificate":
              return (
                <VoipScreenSix
                  handleFormUpdate={handleFormUpdate}
                  regFormInfo={regFormInfo}
                  isIdDocumentUpdated={isIdDocumentUpdated}
                  setIdCardFrontName={setIdCardFrontName}
                  setIdCardBackName={setIdCardBackName}
                  setIdName={setIdName}
                />
              );
            case "profOfAddress":
              return (
                <VoipScreenSeven
                  handleFormUpdate={handleFormUpdate}
                  regFormInfo={regFormInfo}
                />
              );
            case "OrderSummary":
              return (
                <OrderSummary
                  handleFormUpdate={handleFormUpdate}
                  regFormInfo={regFormInfo}
                  setOrderTotal={setOrderTotal}
                  setTermsConditions={setTermsConditions}
                  setPersonalInfo={setPersonalInfo}
                />
              );
            case "successPage":
              return <Success regFormInfo={regFormInfo} />;
            default:
              return (
                <Services
                  handleChange={handleChange}
                  handleFormUpdate={handleFormUpdate}
                  regFormInfo={regFormInfo}
                />
              );
          }
        })()}

        <Footer
          handleStep={handleStep}
          handleBackStep={handleBackStep}
          currentStep={currentStep}
          selectedService={selectedService}
          regFormInfo={regFormInfo}
          CartItemInfo={cartItem}
        />
      </RightCont>
    </MainContainer>
  );
};

export default From;
