import React, { useState } from "react";
import {
  MainContainer,
  Heading,
  SubHeading,
  OptionsCont,
  Option,
  SingleOption,
  OptionLabel,
  Title,
} from "./VoipScreenThree.elements";

const VoipScreenThree = ({ handleFormUpdate, regFormInfo }) => {
  const [selected, setSelected] = useState("");
  const [checked, setChecked] = useState(false);
  const [service, setService] = useState("");

  const handleCheck = (event) => {
    setService(event.target.value);
    handleFormUpdate(event);
  };

  return (
    <MainContainer>
      <Heading>Fill Up Service Details</Heading>
      {/* <SubHeading>Are you already a TalkAsia VoIP Service Provider?</SubHeading> */}

      <OptionsCont>
        <Title>What is your source of numbers?</Title>
        <SingleOption
          style={{
            background:
              service == "i buy numbers from other companies" ? "#E5FFF1" : "",
          }}
          for="otherCompanies"
        >
          <Option
            type="radio"
            name="sourceOfNumbers"
            id="otherCompanies"
            value="i buy numbers from other companies"
            onChange={handleCheck}
            checked={
              regFormInfo.sourceOfNumbers ===
              "i buy numbers from other companies"
                ? true
                : false
            }
          />
          <OptionLabel for="otherCompanies">
            I buy numbers from other companies
          </OptionLabel>
        </SingleOption>
        <SingleOption
          style={{
            background:
              service == "i want to use TalkAsia numbers" ? "#E5FFF1" : "",
          }}
          for="talkAsiaNumbers"
        >
          <Option
            type="radio"
            name="sourceOfNumbers"
            id="talkAsiaNumbers"
            value="i want to use TalkAsia numbers"
            onChange={handleCheck}
            checked={
              regFormInfo.sourceOfNumbers === "i want to use TalkAsia numbers"
                ? true
                : false
            }
          />
          <OptionLabel for="talkAsiaNumbers">
            I want to use TalkAsia numbers
          </OptionLabel>
        </SingleOption>
      </OptionsCont>
    </MainContainer>
  );
};

export default VoipScreenThree;
