import styled from "styled-components";
import ReactFlagsSelect from "react-flags-select";

export const MainContainer = styled.div`
  max-width: 1050px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 470px) {
  }
`;

export const Heading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.24px;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
  margin-top: 0px;
`;

export const SubHeading = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #727272;
  margin: 0;
  padding: 0;
  margin-bottom: 38px;
`;

export const Title = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.24px;
  color: #000000;
  margin: 0px;
  margin-bottom: 10px;
`;

export const OptionsCont = styled.div`
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 38px;

  @media (max-width: 470px) {
    width: 90%;
  }
`;

export const OptionLabel = styled.label`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  padding-right: 20px;
`;

export const SingleOption = styled.div`
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  border: 1px solid #36a369;
  margin-bottom: 10px;
  margin-right: 21px;
  box-sizing: border-box;
`;

export const Option = styled.input.attrs({ type: "radio" })`
  width: 20px;
  height: 20px;
  appearance: none;
  -webkit-appearance: none;

  &:after {
    display: block;
    content: "";
    width: 20px;
    height: 20px;
    border: 1px solid #36a369;
    border-radius: 50%;
  }

  &:checked::after {
    content: url("assets/images/check-bg.svg");
    width: 20px;
    height: 20px;
    border: none;
  }

  &:checked + ${OptionLabel} {
    font-weight: 600;
    color: #1e623e;
  }
`;

export const CustomAgent = styled.input.attrs({
  type: "number",
  id: "customAgentNum",
})`
  width: 100%;
  height: 38px;
  background: ${(props) => (props.customAgentNum ? "transparent" : "#D7D7D7")};
  display: flex;
  align-items: center;
  border: 1px solid #36a369;
  margin-bottom: 14px;
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #989898;
  padding: 0px 19px;

  ::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #989898;
  }
`;

export const Country = styled(ReactFlagsSelect)`
  width: 100%;
  height: 34px;
  border: 1px solid #36a369;
  margin-bottom: 18px;
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #989898;
  padding: 0px 19px;
  outline: none;

  ::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #989898;
  }

  button {
    border: none;
  }
`;

// export const div = styled.div``;
// export const div = styled.div``;
