import React, { useState, useRef } from "react";
import {
  MainContainer,
  Heading,
  SubHeading,
  ExtraInfo,
  InputCont,
  Title,
  Table,
  Tr,
  Th,
  Td,
  NoOfDid,
  Error,
  Green,
  Content,
  Text,
  Input,
  SignatureWrapper,
  SignatureContainer,
  SignatureButtons,
  SignatureImg,
} from "./DialerVoipScreenNew.elements";
import SignatureCanvas from "react-signature-canvas";
const DialerVoipScreenNew = ({
  handleFormUpdate,
  regFormInfo,
  handleSignatureInput,
}) => {
  const [showSignaturePad, setShowSignaturePad] = useState(false);
  const [signature, setSignature] = useState("");

  const handelSignature = () => {
    const canvas = document.querySelector(".sigCanvas");
    const signature = canvas.toDataURL();
    setSignature(signature);
    handleSignatureInput(signature);
    setShowSignaturePad(false);
  };

  const handleCheck = (event) => {
    handleFormUpdate(event);
  };

  return (
    <MainContainer>
      <Heading> “Know You Customer” Robo Fraud Policy </Heading>
      {/* <SubHeading>
        To avoid delays when verifying account, please make sure below:
      </SubHeading> */}
      <Content>
        {showSignaturePad && (
          <SignatureWrapper>
            <SignatureContainer>
              <SignatureCanvas
                canvasProps={{
                  width: 400,
                  height: 200,
                  className: "sigCanvas",
                }}
              />
            </SignatureContainer>
            <SignatureButtons onClick={handelSignature}>
              Submit
            </SignatureButtons>
          </SignatureWrapper>
        )}

        <Text>
          The US government and US Telecom Traceback group have enhanced their
          efforts to tackle the crippling U.S. fraud & Robo Calling epidemic.
          TalkAsiaVoip LLC has always been compliant with all US Agencies and is
          a good standing member of the ITG US Telecom Traceback Group
          Community. As a member, we are taking an aggressive stance to help
          battle the Fraudulent and illegal ROBO calling that has plagued all
          consumers. As a warning, there are a lot of offshore companies,
          signing up for accounts with US carriers, to terminate their calls.
          Fraudulent Call Centers are looking for new homes in order to
          terminate these “Scam Calls” by masquerading themselves as Social
          Security, IRS, law enforcement and other government departments calls.
          TalkAsiaVoip only accepts traffic that complies with applicable United
          States laws and regulations and will not work with any companies who
          are offshore and/or are not registered with the FCC and found in the
          “RoboCall Mitigation Database” “RMD.”
        </Text>
        <Text>In our efforts, TalkAsiaVoip follows these procedures:</Text>
        <Text>
          ● All customers originating traffic in violation of the FCC Trace Act,
          such as robocalls, calls impersonating Social Security, IRS, Law
          Enforcement or other government departments, and traversing the
          TalkAsiaVoip Network, will receive an initial violation warning.
        </Text>
        <Text>
          ● A second offense of this nature may constitute a suspension.
        </Text>
        <Text>
          ● Upon a consecutive third offense, the account may be suspended
          pending termination until a mitigation plan is in place.
        </Text>
        <Text>
          ● All tracebacks must be responded to immediately, and end-users that
          are non compliant, removed from the network
        </Text>
        <Text>
          ● If fraud or robocalling continues after notification, your account
          will then be terminated immediately and permanently. The Broadband
          association requires all clients to reply to tracebacks, passing on
          the customer information that originated the call that is flagged for
          the fraud.
        </Text>
        <Text>
          ● If you don’t reply to tracebacks, you get a strike against your
          company and TalkAsiaVoip also gets a strike.
        </Text>
        <Text>
          ● If you do not fill out the traceback form, you will then be treated
          as the end-user who committed the fraud call and TalkAsiaVoip will
          terminate your account from our network.
        </Text>
        <Text>
          ● Suspension and/or Termination is up to the discretion of
          TalkAsiaVoip LLC.
        </Text>
        <Text>
          My signature below is in representation of
          <Input
            type="text"
            placeholder="Company name"
            value={regFormInfo.companyName}
            disabled
          />
        </Text>
        <Text>
          ● Agree that I have read and do hereby recognize the “Know Your
          Customer” Robo Fraud Policy as set forth by TalkAsiaVoip LLC.
        </Text>
        <Text>
          ● Do hereby agree to comply with all rules regulations as set forth by
          the FCC, and its governing bodies – “Industry Traceback Group,” “US
          Telecom,” and the policies of the “TalkAsiaVoip LLC” network.
        </Text>
        <Text>
          ● Agree to participate, respond, and mitigate any and all traceback
          requests put forth by US Telecom Traceback Group in representation of
          the FCC’s Industry Traceback Group (ITG), in a timely manner.
        </Text>
        <Text>
          ● That our company is a Terminating Service Provider (TSP) listed in
          the Robocall Mitigation Database (RMD) and that all customers are
          vetted prior to sending any call to the TalkAsiaVoip LLC network.
        </Text>
        <Text>● Our (Customer) listing in the RMD may be located as such:</Text>
      </Content>
      <Content>
        <Text>
          ● RMD#:{" "}
          <Input
            type="text"
            placeholder="RMD No"
            name="kycRmd"
            value={regFormInfo.kycRmd}
            onChange={handleCheck}
          />{" "}
          Date:
          <Input
            type="date"
            placeholder="Date"
            name="kycDate"
            value={regFormInfo.kycDate}
            onChange={handleCheck}
          />
        </Text>
        <Text>
          Compliance Officer Signature:
          {signature ? (
            <SignatureImg
              src={signature}
              onClick={() => setShowSignaturePad(true)}
            />
          ) : (
            <Input
              placeholder="Signature"
              onClick={() => setShowSignaturePad(true)}
            />
          )}
          Compliance Officer Name (Printed):
          <Input
            type="text"
            placeholder="Officer Name"
            name="kycName"
            value={regFormInfo.kycName}
            onChange={handleCheck}
          />
        </Text>
      </Content>
    </MainContainer>
  );
};

export default DialerVoipScreenNew;
