import React, { useState, useEffect } from "react";
import {
  MainContainer,
  Heading,
  SubHeading,
  OptionsCont,
  Option,
  SingleOption,
  OptionLabel,
  Title,
  LabelImage,
  ChekCont,
  CheckBox,
  CheckLabel,
  SingleCheck,
  SubmitCont,
  SubmitButton,
  OrderItem,
  OrderItemHeading,
  OrderItemValue,
  TermsCont,
  TermsHeading,
  TermsDesc,
  InfoCont,
  InfoIcon,
  InfoText,
  ReadTerms,
} from "./OrderSummary.elements";

import axios from "axios";
import { CALCULATE_TOTALS } from "../Endpoints";
import { REGISTER_INVOICE } from "../Endpoints";
import { SEND_CONFIRMATION_EMAIL } from "../Endpoints";

const OrderSummary = ({
  handleFormUpdate,
  regFormInfo,
  setOrderTotal,
  setTermsConditions,
  setPersonalInfo,
}) => {
  const [selected, setSelected] = useState("");
  const [checked, setChecked] = useState(false);
  const [terms, setTerms] = useState("");
  const [personalInformation, setPersonalInformation] = useState("");
  const [termsRead, setTermsRead] = useState(true);
  const [scrollTop, setScrollTop] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const sendConfirmationEmail = async () => {
      try {
        const sendEmail = await axios.post(SEND_CONFIRMATION_EMAIL, {
          email: regFormInfo.email,
        });
        console.log(sendEmail.data);
      } catch (error) {
        console.log(error);
      }
    };
    const registerInvoice = async () => {
      const data = regFormInfo;
      data.invoiceStatus = "pending";
      const response = await axios.post(REGISTER_INVOICE, data);
      console.log(response);
    };

    registerInvoice();
    sendConfirmationEmail();
  }, []);

  useEffect(() => {}, []);

  const handleCheckTerms = (event) => {
    if (terms === "accepted") {
      setTermsConditions("");
      setTerms("");
    } else {
      setTermsConditions(event.target.value);
      setTerms("accepted");
    }
  };

  const handleCheckPersonalInfo = (event) => {
    if (personalInformation === "accepted") {
      setPersonalInfo("");
      setPersonalInformation("");
    } else {
      setPersonalInfo(event.target.value);
      setPersonalInformation("accepted");
    }
  };

  const myRef = React.createRef();

  const onScroll = () => {
    const scrollY = window.scrollY;
    const scrollTop = myRef.current.scrollTop;
    if (myRef.current.scrollTop >= 150) {
      setTermsRead(true);
    } else {
      setTermsRead(true);
    }
    console.log(
      `onScroll, window.scrollY: ${scrollY} myRef.scrollTop: ${scrollTop}`
    );
    setScrollTop(scrollTop);
  };

  return (
    <MainContainer>
      <Heading>Your Order Summary</Heading>
      <SubHeading>Review your order summary and confirm.</SubHeading>

      <OptionsCont>
        <OrderItem>
          <OrderItemHeading>Service:</OrderItemHeading>
          <OrderItemValue> {regFormInfo.service}</OrderItemValue>
        </OrderItem>
        {regFormInfo.service === "dialervoip" ||
        regFormInfo.service === "dialer" ? (
          <>
            <OrderItem>
              <OrderItemHeading>Number Of Agents:</OrderItemHeading>
              <OrderItemValue> {regFormInfo.noOfAgents}</OrderItemValue>
            </OrderItem>

            <OrderItem>
              <OrderItemHeading>Server Type:</OrderItemHeading>
              <OrderItemValue> {regFormInfo.serverType}</OrderItemValue>
            </OrderItem>

            <OrderItem>
              <OrderItemHeading>Need Dialer Support?:</OrderItemHeading>
              <OrderItemValue>
                {" "}
                {regFormInfo.service === "dialervoip"
                  ? "Free"
                  : regFormInfo.dedicatedSupport}
              </OrderItemValue>
            </OrderItem>
          </>
        ) : (
          ""
        )}

        {regFormInfo.service !== "amazon" ? (
          <OrderItem>
            <OrderItemHeading>Order Total: </OrderItemHeading>
            <OrderItemValue> ${regFormInfo.total}</OrderItemValue>
          </OrderItem>
        ) : (
          ""
        )}
      </OptionsCont>

      {/* <TermsCont ref={myRef} onScroll={onScroll}>
        <TermsHeading>Terms & Services</TermsHeading>
        <TermsDesc>
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content It is a long
          established fact that a reader will be distracted by the readable
          content of a page when looking at its layout. The point of using Lorem
          Ipsum is that it has a more-or-less normal distribution of letters, as
          opposed to using 'Content here, content It is a long established fact
          that a reader will be distracted by the readable content of a page
          when looking at its layout. The point of using Lorem Ipsum is that it
          has a more-or-less normal distribution of letters, as opposed to using
          'Content here, content It is a long established fact that a reader
          will be distracted by the readable content of a page when looking at
          its layout. The point of using Lorem Ipsum is that it has a
          more-or-less normal distribution of letters, as opposed to using
          'Content here, content
        </TermsDesc>
      </TermsCont> */}

      <ChekCont>
        <SingleCheck>
          <CheckBox
            type="checkbox"
            name="TermsConditionsAccepted"
            id="TermsConditionsAccepted"
            value="accepted"
            onChange={handleCheckTerms}
            disabled={termsRead ? false : true}
            checked={
              regFormInfo.TermsConditionsAccepted === "accepted" ? true : false
            }
          />

          <CheckLabel
            for="TermsConditionsAccepted"
            style={{ color: !termsRead ? "#c7c3c3" : "#727272" }}
          >
            I have read the{" "}
            <ReadTerms href="https://talkasiavoip.com/terms" target="_blank">
              terms & conditions
            </ReadTerms>
          </CheckLabel>
        </SingleCheck>

        <SingleCheck>
          <CheckBox
            type="checkbox"
            name="personalInformationConsent"
            id="personalInformationConsent"
            value="accepted"
            onChange={handleCheckPersonalInfo}
            disabled={termsRead ? false : true}
            checked={
              regFormInfo.personalInformationConsent === "accepted"
                ? true
                : false
            }
          />
          <CheckLabel
            for="personalInformationConsent"
            style={{ color: !termsRead ? "#c7c3c3" : "#727272" }}
          >
            All the provided data is accurate as per my knowledge
          </CheckLabel>
        </SingleCheck>
      </ChekCont>

      <InfoCont>
        <InfoIcon src="assets/images/info-summary.svg" />
        <InfoText>
          If you press next you <b>cannot</b> change any information
        </InfoText>
      </InfoCont>
    </MainContainer>
  );
};

export default OrderSummary;
