import React, { useState } from "react";
import {
  MainContainer,
  Heading,
  SubHeading,
  OptionsCont,
  Option,
  SingleOption,
  OptionLabel,
  Title,
} from "./VoipScreenOne.elements";

const VoipScreenOne = ({ handleFormUpdate, regFormInfo }) => {
  const [selected, setSelected] = useState("");
  const [checked, setChecked] = useState(false);
  const [service, setService] = useState("");

  const handleCheck = (event) => {
    setService(event.target.value);
    handleFormUpdate(event);
  };

  return (
    <MainContainer>
      <Heading>Fill Up Service Details</Heading>
      {/* <SubHeading>Are you already a TalkAsia VoIP Service Provider?</SubHeading> */}

      <OptionsCont>
        <Title>What services do you need?</Title>
        <SingleOption
          style={{
            background: service == "dialer termination" ? "#E5FFF1" : "",
          }}
          for="dialerTermination"
        >
          <Option
            type="radio"
            name="serviceType"
            id="dialerTermination"
            value="dialer termination"
            onChange={handleCheck}
            checked={
              regFormInfo.serviceType === "dialer termination" ? true : false
            }
          />
          <OptionLabel for="dialerTermination">Dialer Termination</OptionLabel>
        </SingleOption>
        <SingleOption
          style={{ background: service == "conversational" ? "#E5FFF1" : "" }}
          for="conversational"
        >
          <Option
            type="radio"
            name="serviceType"
            id="conversational"
            value="conversational"
            onChange={handleCheck}
            checked={
              regFormInfo.serviceType === "conversational" ? true : false
            }
          />
          <OptionLabel for="conversational">Conversational</OptionLabel>
        </SingleOption>
      </OptionsCont>
    </MainContainer>
  );
};

export default VoipScreenOne;
